import CircularLoading from 'components/atom/Loading/CircularLoading';
import Icone from 'components/Icone';
import Modal from 'components/modal/base';
import { appendPrependString } from 'libs/StringUteis';
import React, { FC, useMemo } from 'react';
import { Observer } from "mobx-react-lite"
import { useQueryData } from './hooks/useQueryData';
import { Container, Header, Secao, Table, WarpLoader } from './styl';
import {
    calculaValorFreteComTaxaDetalhes,
    formatarMoeda,
} from '../../../../libs/NumberUtils';

const PageColetaDetalhes: FC<any> = () => {
    const { store } = useQueryData();
    useMemo(() => {
        if(store.configuracao && store.configuracao.length > 0){
            const distanciaMinima = store.configuracao?.find(
                config => config.chave === 'Frete::DistanciaMinima::Km',
            )?.valor;
            const valorAdicionalCorrida = store.configuracao.find(
                ({ chave }) => chave === 'Valor::Adicional::Corrida',
            )?.valor;
            const viewValorTotalFrete =
                store.coleta.unidade &&
                store.coleta.unidade?.unidadeParametro &&
                store.coleta.unidade?.unidadeParametro.find(
                    ({ chave }) => chave === 'VIEW::VALOR::TOTAL::FRETE',
                )?.valor === 'YES';
            const tmp = {
                valorFreteComRetorno: 0,
                valorFreteSemRetorno: 0,
            };
            const result = calculaValorFreteComTaxaDetalhes({
                frete: store.coleta.valorFrete,
                porcentagemNegociacao: store.coleta.unidade?.porcentagemNegociacao,
                txMinRetorno: store.coleta.modalTransporte?.txMinRetorno,
                distanciaMinima: parseInt(distanciaMinima),
                adcionalRetorno: store.coleta.modalTransporte?.valorKmRetorno,
                distancia:
                    store.coleta.cliente?.endereco?.frete[0].distancia +
                    store.coleta.cliente?.endereco?.frete[0].precisao,
                mostrarValorTotal: viewValorTotalFrete,
                valorAdicionalCorrida: valorAdicionalCorrida
                    ? Number(valorAdicionalCorrida)
                    : 0,
            });
            if (!store.frete) {
                if (store.coleta.pagamento?.exigeTaxaRetorno) {
                    store.setFrete(result.valorFreteComRetorno);
                } else if (store.coleta && store.coleta.pagamento) {
                    store.setFrete(result.valorFreteSemRetorno);
                }
            }
        }
    }, [store.coleta]);
    if (store.loading && !store.coleta.id) {
        return (
            <Modal
                render={({ closeModal }) => (
                    <Container>
                        <Header>
                            <h1>Detalhes da coleta</h1>
                            <button onClick={closeModal}>
                                <Icone
                                    icone="icon-fechar"
                                    size="2rem"
                                    color="var(--btn-admin-icone)"
                                />
                            </button>
                        </Header>
                        <WarpLoader>
                            <CircularLoading
                                show={true}
                                size={3}
                                primaryColor="var(--btn-underline-cor)"
                            />
                        </WarpLoader>
                    </Container>
                )}
            />
        );
    }
    return (
        <Observer>
            {() => (
                <Modal
                    render={({ closeModal }) => (
                        <Container>
                            <Header>
                                <h1>Detalhes da coleta</h1>
                                <button onClick={closeModal}>
                                    <Icone
                                        icone="icon-fechar"
                                        size="2rem"
                                        color="var(--btn-admin-icone)"
                                    />
                                </button>
                            </Header>
                            <>
                                <Secao>
                                    <h2>Coleta</h2>
                                    <p>
                                        <strong>ID: </strong>
                                        {store.coleta.numeroInterno}
                                    </p>
                                    <p>
                                        <strong>Unidade: </strong>
                                        {store.coleta.unidade.nomeFantasia}
                                    </p>
                                    {store.coleta.valorTotalProdutos > 0 && (
                                        <p>
                                            <strong>Total: </strong>
                                            {formatarMoeda(
                                                store.coleta.valorTotalProdutos || 0,
                                            )}
                                        </p>
                                    )}
                                    
                                        <p>
                                            <strong>Jogo Rápido: </strong>
                                            {formatarMoeda(store.frete)}
                                        </p>
                                    

                                    <p>
                                        <strong>Forma Pagamento: </strong>
                                        {store.coleta.pagamento.formaPagamentoNome}
                                        {store.coleta.pagamento.formaPagamentoId === 1 &&
                                            ` (troco ${(
                                                store.coleta.pagamento?.valorTroco || 0
                                            ).toFixed(2)})`}
                                    </p>
                                </Secao>
                                <Secao>
                                    <h2>Cliente</h2>
                                    <p>
                                        <strong>Nome: </strong>
                                        {store.coleta.cliente.nomeCompleto}
                                    </p>
                                    <p>
                                        <strong>Celular: </strong>
                                        {store.coleta.cliente.telefoneCelular}
                                    </p>
                                    <p>
                                        <strong>Telefone: </strong>
                                        {store.coleta.cliente.telefoneFixo}
                                    </p>
                                </Secao>
                                {store.coleta.entregador.id && (
                                    <Secao>
                                        <h2>Entregador</h2>
                                        <p>
                                            <strong>Nome: </strong>
                                            {store.coleta.entregador.nomeCompleto}
                                        </p>
                                        <p>
                                            <strong>Telefone: </strong>
                                            {store.coleta.entregador.telefone}
                                        </p>
                                    </Secao>
                                )}
                                <Secao>
                                    <h2>Endereços</h2>
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th>Endereço</th>
                                                <th>Distância / Tempo</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    {
                                                        store.coleta.cliente.endereco
                                                            ?.enderecoLogradouro
                                                    }
                                                    {appendPrependString(
                                                        store.coleta.cliente.endereco
                                                            ?.enderecoNumero,
                                                        ', nº ',
                                                    )}
                                                    {appendPrependString(
                                                        store.coleta.cliente.endereco
                                                            ?.enderecoQuadra,
                                                        ', Quadra ',
                                                    )}
                                                    {appendPrependString(
                                                        store.coleta.cliente.endereco
                                                            ?.enderecoLote,
                                                        ', Lote ',
                                                    )}
                                                    {appendPrependString(
                                                        store.coleta.cliente.endereco
                                                            ?.enderecoComplemento,
                                                        ', ',
                                                    )}
                                                    {appendPrependString(
                                                        store.coleta.cliente.endereco
                                                            ?.enderecoPontoReferencia,
                                                        ', ',
                                                    )}
                                                    {appendPrependString(
                                                        store.coleta.cliente.endereco
                                                            ?.enderecoCep,
                                                        ' - ',
                                                    )}
                                                    {appendPrependString(
                                                        store.coleta.cliente.endereco
                                                            ?.enderecoBairro,
                                                        ' - ',
                                                    )}
                                                </td>
                                                <td>
                                                    {store.coleta.cliente.endereco
                                                        ?.metricaDistUnidade
                                                        ? `${store.coleta.cliente.endereco?.metricaDistUnidade} Km `
                                                        : ' - '}
                                                    /
                                                    {store.coleta.cliente.endereco
                                                        ?.metricaTempoUnidade
                                                        ? ` ${store.coleta.cliente.endereco?.metricaTempoUnidade.toFixed(
                                                            0,
                                                        )} minutos`
                                                        : ' - '}
                                                </td>
                                                {/* <td width="100">
                                                        R${' '}
                                                        {store.coleta.valorFrete.toFixed(2)}
                                                    </td> */}
                                            </tr>
                                            {store.coleta.historicoEnderecos.map(endereco => {
                                                if (
                                                    endereco.id !==
                                                    store.coleta.cliente.endereco.id
                                                ) {
                                                    return (
                                                        <tr>
                                                            <td>
                                                                {
                                                                    endereco?.enderecoLogradouro
                                                                }
                                                                {appendPrependString(
                                                                    endereco?.enderecoNumero,
                                                                    ', nº ',
                                                                )}
                                                                {appendPrependString(
                                                                    endereco?.enderecoQuadra,
                                                                    ', Quadra ',
                                                                )}
                                                                {appendPrependString(
                                                                    endereco?.enderecoLote,
                                                                    ', Lote ',
                                                                )}
                                                                {appendPrependString(
                                                                    endereco?.enderecoComplemento,
                                                                    ', ',
                                                                )}
                                                                {appendPrependString(
                                                                    endereco?.enderecoPontoReferencia,
                                                                    ', ',
                                                                )}
                                                                {appendPrependString(
                                                                    endereco?.enderecoCep,
                                                                    ' - ',
                                                                )}
                                                                {appendPrependString(
                                                                    endereco?.enderecoBairro,
                                                                    ' - ',
                                                                )}
                                                            </td>
                                                            <td>
                                                                {endereco?.metricaDistUnidade
                                                                    ? `${endereco?.metricaDistUnidade} Km `
                                                                    : ' - '}
                                                                /
                                                                {endereco?.metricaTempoUnidade
                                                                    ? ` ${endereco?.metricaTempoUnidade.toFixed(
                                                                        0,
                                                                    )} minutos`
                                                                    : ' - '}
                                                            </td>
                                                            {/* <td width="100">
                                                                    R${' '}
                                                                    {store.coleta.valorFrete.toFixed(
                                                                        2
                                                                    )}
                                                                </td> */}
                                                        </tr>
                                                    );
                                                }
                                            })}
                                        </tbody>
                                    </Table>
                                </Secao>
                            </>
                        </Container>
                    )}
                />
            )}
        </Observer>
    );
};
export default PageColetaDetalhes;
