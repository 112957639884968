// REPOST
import { useCallback, useMemo } from 'react';
import { IEntregador, IEscalas } from './useQuerySubscriptionFilter/index.d';
import { IPeriodo } from './useQueryPeriod/index.d';

export const useTransformScale = (
    periodos?: IPeriodo[],
    turno?: 'Café' | 'Almoço' | 'Lanche' | 'Janta' | 'Madrugada'
) => {
    const indexs = useMemo(() => {
        return periodos.map(({ id }) => +id);
    }, [periodos]);
    const handleIsActived = useCallback(
        (escala: IEscalas) => {
            let status =
                escala &&
                escala?.periodo &&
                escala?.periodo?.ativo &&
                escala?.dataRegistro
                    ? 'ativo'
                    : '';

            let icone = 'icon-like';
            if (status === 'ativo') {
                icone = 'icon-like';
                if (escala.dataCancelamento) {
                    icone = 'icon-deslike';
                    status = 'cancelado';
                }
                if (escala.dataEncerramento) {
                    icone = 'icon-sino3';
                    status = 'cancelado';
                }
            } else {
                icone = 'icon-click-like';
            }
            return { status, ativo: status === 'ativo', icone };
        },
        [indexs]
    );
    const handleTransformScale = useCallback(
        (entregador: IEntregador) => {
            const clone = periodos?.map((value) => {
                return {
                    ...value,
                    data: {
                        entregadorId: entregador.id,
                        ativo: false,
                        status: entregador.status,
                        periodoId: value.id,
                    },
                };
            });
            entregador.escalas?.forEach((escala) => {
                //
                const index = indexs.indexOf(escala?.periodoId);

                const { icone, status, ativo } = handleIsActived(escala);

                if (index !== -1) {
                    clone[index] = {
                        ...clone[index],
                        status, // representação de cor do icone
                        icone,
                        data: {
                            entregadorId: entregador.id,
                            ativo,
                            periodoId: escala?.periodoId,
                            status, // verifica se pode escalar o player ou não.
                        },
                    };
                }
            });
            const result = clone.filter((value) => {
                return turno ? value.titulo === turno : true;
            });

            return result;
        },
        [periodos, turno]
    );
    return { handleTransformScale };
};
