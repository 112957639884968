import { useQuery, useSubscription } from '@apollo/client';
import { usePaginationAndFilter } from 'components/template/ListTemplate/hooks/usePaginationAndFilter';
import { CLIENTE_SUBSCRIPTION } from 'gql/cliente';
import { COLETA_SUBSCRIPTION, GRAPHQL_LISTAR_COLETA } from 'gql/coleta';
import { GRAPHQL_CONFIGURACAO } from 'gql/configuracao';
import { useEffect, useState, useRef } from 'react';
import { IConfiguracao } from 'types/modules/configuracao';
import JSON_DATA_FILTER from '../../data/filter.json';
import { IColeta } from '../useTransformColeta/index.d';
import { ISubscriptionColeta } from './index.d';
import { GRAPHQL_UNIDADE_SUBSCRIPTION } from 'gql/unidade';
import { ISubscriptionUnidade } from '../../../../../pages/unidades/list/hooks/useQueryFilterUnit/index.d';
import { useClientApollo } from 'routes/hooks/useClientApollo';

export const useQuerySubscriptionFilter = () => {
    const { client } = useClientApollo();
    const timeAdicionalCollectt = useRef(0);
    const timeAwaitUnitCollectt = useRef(10);
    const props = usePaginationAndFilter({
        dataKeyName: 'dataPedido',
        keysQueryVariavel: [
            'dataPedido',
            'id',
            'nomeEstabelecimento',
            'entregadorId',
            'entregadorNome',
            'razaoSocial',
            'numeroInterno',
            'clienteNome',
            'clienteTelefoneCelular',
            'statusPedido',
            'dataPedido',
            'turno',
        ],
        callbackHandleSearch: (data) => {
            const variables = {
                filter: {
                    ...props.variables.filter,
                    ...data,
                },
            }
            initRecuperarColeta(variables);
        }
    });

    const [entregadoresConnection, setEntregadoresConnection] = useState({});
    useSubscription(CLIENTE_SUBSCRIPTION, {
        onSubscriptionData({
            subscriptionData: {
                data: {
                    cliente: { data, action },
                },
            },
        }) {
            if (props.response) {
                if (action !== 'DELETE') {
                    const coletas: IColeta[] = props.response.map(coleta => {
                        if (coleta.cliente.id === data.id) {
                            coleta.cliente = { ...coleta.cliente, ...data };
                        }
                        return coleta;
                    });
                    props.handleSetResponse(coletas);
                }
            }
        },
    });

    // useSubscription<ISubscriptionEntregador>(ENTREGADOR_SUBSCRIPTION, {
    //     variables: props.variables,
    //     onSubscriptionData: ({
    //         subscriptionData: {
    //             data: {
    //                 entregador: { data },
    //             },
    //         },
    //     }) => {

    //         setEntregadoresConnection(prev => ({
    //             ...prev,
    //             [data.id]: data.connection,
    //         }));
    //         const response = props.response.map(coleta => {
    //             if (coleta.entregador && coleta.entregador.id === data.id) {
    //                 coleta.entregador.connection = data.connection;
    //             }
    //             return coleta;
    //         });
    //         props.handleSetResponse(response);
    //     },
    // });
    // useSubscription<ISubscription>(ENTREGADOR_LOCATION_SUBSCRIPTION, {
    //     onSubscriptionData: ({
    //         subscriptionData: {
    //             data: {
    //                 entregadorLocation: { data },
    //             },
    //         },
    //     }) => {
    //         setEntregadoresConnection(prev => ({
    //             ...prev,
    //             [data.entregadorId]: data.connection,
    //         }));
    //     },
    // });
    useSubscription<ISubscriptionColeta>(COLETA_SUBSCRIPTION, {
        variables: props.variables,
        onSubscriptionData: response => {
            const {
                subscriptionData: {
                    data: {
                        coleta: { data },
                    },
                },
            } = response;
            props.onSubscriptionData(data);
        },
    });

    useQuery<{
        recuperarConfiguracao?: IConfiguracao[];
    }>(GRAPHQL_CONFIGURACAO, {
        onCompleted: ({ recuperarConfiguracao }) => {
            const timeAditionalStatus = recuperarConfiguracao.find(
                ({ chave }) => chave === 'Coleta::MargemErro::Checkin',
            );
            const timeAwait = recuperarConfiguracao.find(
                ({ chave }) =>
                    chave === 'Entregador::Espera::Estabelecimento::Minutos',
            );
            if (
                timeAditionalStatus &&
                timeAditionalStatus.valor &&
                +timeAditionalStatus.valor > 0
            ) {
                timeAdicionalCollectt.current = +timeAditionalStatus.valor;
            } else {
                timeAdicionalCollectt.current = 1;
            }
            if (timeAwait && timeAwait.valor && +timeAwait.valor > 0) {
                timeAwaitUnitCollectt.current = +timeAwait.valor;
            }
        },
    });

    useSubscription<ISubscriptionUnidade>(GRAPHQL_UNIDADE_SUBSCRIPTION, {
        variables: {},
        onSubscriptionData: ({
            subscriptionData: {
                data: {
                    unidade: { data },
                },
            },
        }) => {
            const findUnidade = props.response.find(
                ({ unidade }) => unidade.id === data.id,
            );
            if (findUnidade) {
                const newProps = props.response.map(coleta => {
                    if (coleta.unidade.id === data.id) {
                        coleta.unidade = data;
                    }
                    return coleta;
                } );
                props.onSubscriptionData(newProps)
            }
        },
    });

    // const { loading } = useQuery<IQueryColeta>(GRAPHQL_LISTAR_COLETA, {
    //     fetchPolicy: 'cache-and-network',
    //     context: props.context,
    //     variables: props.variables,
    //     onCompleted: ({ recuperarColeta }) =>
    //         props.onCompleted(recuperarColeta),
    //     onError: props.onError,
    // });

    const initRecuperarColeta = (variables?: any) => {
        props.setLoading(true);
        client.query({
            fetchPolicy: 'no-cache',
            query: GRAPHQL_LISTAR_COLETA,
            context: props.context,
            variables: variables ? variables : props.variables
        }).then(({ data }) => {
            props.onCompleted(data.recuperarColeta); 
        }
        ).catch(error => {
            props.onError(error
            );  
        }).finally(() => {
            props.setLoading(false);
        });
    }

    useEffect(() => {
        initRecuperarColeta();
    }, []);

    // useEffect(() => {
    //     props.setLoading(loading);
    // }, [loading]);

    return {
        ...props,
        timeAdicionalCollectt: timeAdicionalCollectt.current,
        timeEsperaEstabelecimento: timeAwaitUnitCollectt.current,
        dataSelectHeader: JSON_DATA_FILTER,
        entregadoresConnection,
    };
};
