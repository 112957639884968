import { abreviarNome, appendPrependString } from 'libs/StringUteis';
import { useCallback, useMemo } from 'react';
import { useModalHistory } from 'routes/admin/hook/useModalHistory';
import { IUnidade } from './useQueryFilterUnit/index.d';
import { useToggleActiveUnit } from './useToggleActiveUnit';
import { useHistory } from 'react-router-dom';
import { useToggleActiveColeta } from './useToggleActiveColeta';
const tipoPagamento = {
    PRE_PAGO: 'Pré-pago',
    POS_PAGO: 'Pós-pago',
    AMBOS: 'Ambos',
};
export const useTransformUnit = (
    unidades: IUnidade[] = [],
    { administrador }
) => {
    const history = useModalHistory();
    const historyDom = useHistory();

    const { handleToogleActive } = useToggleActiveUnit();
    const { handleToogleColeta } = useToggleActiveColeta();

    const handleOpenModalRelatorio = useCallback(({ id }) => {
        history.push(`relatorio/${id}`);
    }, []);

    const handleEmpresaBanco = useCallback(({ id }) => {
        history.push(`banco/${id}`);
    }, []);
    const handleEmpresaConfiguracoes = useCallback(({ id }) => {
        history.push(`configuracao/${id}`);
    }, []);

    const handleOpenMap = useCallback(
        (data) => {
            history.push(`mapa/${data.geoLatitude}/${data.geoLongitude}`);
        },
        [history]
    );
    const handleTypePayment = useCallback(
        (data) => {
            history.push(`tipo-pagamento/${data.id}`);
        },
        [history]
    );
    const handleInsertBalance = useCallback(
        (data) => {
            history.push(`inserir-saldo/${data.id}`);
        },
        [history]
    );

    const handleUsuariosDeAcesso = useCallback(
        (data) => {
            history.push(`permissoes/${data.id}`);
        },
        [history]
    );
    const handleNovoUsuario = useCallback(
        (data) => {
            historyDom.push(`/admin/usuario/editar~usuario`);
        },
        [history]
    );
    const handleAdministradores = useCallback(
        (data: IUnidade) => {
            historyDom.push(`/admin/usuario`);
        },
        [history]
    );
    const handleEditarEmpresa = useCallback(
        (data) => {
            history.push(`editar-cadastro/${data.id}`);
        },
        [history]
    );
    const handleEditarValueAppAndKeysIfood = useCallback(
        ({ id }) => {
            history.push(`configurar-value-app-key-ifood/${id}`);
        },
        [history]
    );

    const data = useMemo(() => {
        return unidades.map((unidade) => {
            let blockColeta = unidade.unidadeParametro?.find(
                ({ chave }) => chave === 'BLOCK::COLETA'
            )?.valor;
            const boolBlockColeta = blockColeta === 'NO' || !blockColeta ? false : true;
            const inadimplente = unidade.unidadeFatura?.find(
                ({ adimplente }) => !adimplente
            );

            const responseMail = unidade?.unidadeContatos?.find(
                ({ tipo }) => tipo === 'email'
            );
            const email = responseMail?.contato || '';
            const telefones = unidade?.unidadeContatos
                ?.filter(
                    ({ tipo }) => tipo != 'email' && tipo != 'email-financeiro'
                )
                ?.map(({ contato }) => contato)
                .splice(0, 2)
                .join(' | ');

            const enderecoLogradouro = appendPrependString(
                unidade.enderecoLogradouro,
                ''
            );
            const enderecoNumero = appendPrependString(
                unidade?.enderecoNumero,
                ', nº '
            );
            const enderecoQuadra = appendPrependString(
                unidade?.enderecoQuadra,
                ', quadra '
            );
            const enderecoLote = appendPrependString(
                unidade?.enderecoQuadra,
                ', lote '
            );
            const enderecoComplemento = appendPrependString(
                unidade?.enderecoComplemento,
                ''
            );

            const bairro = appendPrependString(unidade?.enderecoBairro, '');
            const enderecoCep = appendPrependString(
                unidade?.enderecoCep,
                ' - '
            );
            const percent = (unidade?.porcentagemNegociacao * 100).toFixed(0);
            const botaoRelatorio = {
                titulo: 'Relatório financeiro',
                click: handleOpenModalRelatorio,
                data: unidade,
                icone: 'icon-ajustar',
            };

            const botoes = administrador
                ? [
                      {
                          titulo: 'Tipo de pagamento',
                          placeholder: 'Tipo de pagamento (pré-pago, pós-pago ou ambos)',
                          click: handleTypePayment,
                          data: unidade,
                          icone: 'icon-selecionar2',
                      },

                      {
                          titulo: 'Localização da empresa',
                          placeholder: 'Visualizar localização da empresa',
                          click: handleOpenMap,
                          data: unidade,
                          icone: 'icon-mapa',
                      },
                      {
                          titulo: 'Dados bancários',
                          placeholder: 'Inserir ou alterar os dados bancários',
                          click: handleEmpresaBanco,
                          data: unidade,
                          icone: 'icon-casa-moeda',
                      },
                      {
                        titulo: 'Novo Usuário',
                        click: handleNovoUsuario,
                        data: unidade,
                        icone: 'icon-homem',
                      },
                      {
                          titulo: 'Usuários de acesso',
                          click: handleUsuariosDeAcesso,
                          data: unidade,
                          icone: 'icon-homem',
                      },
                      {
                          titulo: 'Administradores',
                          click: handleAdministradores,
                          data: unidade,
                          icone: 'icon-homem',
                      },
                      {
                          titulo: 'Editar dados da empresa',
                          click: handleEditarEmpresa,
                          data: unidade,
                          icone: 'icon-editar',
                      },
                      botaoRelatorio,
                      {
                            titulo: unidade?.ativo ? 'Empresa ativa' : 'Empresa inativa',
                            placeholder: unidade?.ativo ? 'Empresa ativa. Liberado acesso para criar coletas' : 'Empresa inativa. Sem permissão para criar coletas',
                          click: handleToogleActive,
                          data: unidade,
                          icone: unidade?.ativo
                              ? 'icon-visualizar'
                              : 'icon-naovisualizar',
                      },
                      {
                          titulo: 'Configurar valor a receber do cliente no APP e keys iFood e Anota ai',
                          click: handleEditarValueAppAndKeysIfood,
                          data: unidade,

                          icone: 'icon-chave',
                      },
                  ]
                : [
                    //   {
                    //       titulo: 'Inserir saldo',
                    //       click: handleInsertBalance,
                    //       data: unidade,
                    //       disabled: unidade.tipoPagamento === 'POS_PAGO',
                    //       icone: 'icon-regua',
                    //   },
                      {
                          titulo: 'Visualizar localização da empresa',
                          click: handleOpenMap,
                          data: unidade,
                          icone: 'icon-mapa',
                      },
                      {
                        titulo: 'Novo Usuário',
                        click: handleNovoUsuario,
                        data: unidade,
                        icone: 'icon-homem',
                      },
                      {
                          titulo: 'Usuários de acesso',
                          click: handleUsuariosDeAcesso,
                          data: unidade,
                          icone: 'icon-homem',
                      },
                      {
                          titulo: 'Administradores',
                          click: handleAdministradores,
                          data: unidade,
                          icone: 'icon-homem',
                      },
                    //   {
                    //       titulo: 'Inserir ou alterar os dados bancários',
                    //       click: handleEmpresaBanco,
                    //       data: unidade,
                    //       icone: 'icon-casa-moeda',
                    //   },
                      botaoRelatorio,
                  ];
            return {
                lineClassName: unidade?.ativo && !inadimplente ? '' : 'bgRed',

                colunas: administrador ? [
                    {
                        tipo: 'Texto',
                        width: '4rem',
                        marginRight: '0',
                        titulo: unidade?.prefixoPedido,
                    },
                    {
                        tipo: 'Texto',
                        width: '18rem',
                        marginRight: '0',
                        titulo: unidade?.nomeFantasia,
                        subtitulo: unidade?.identificador,
                    },
                    {
                        tipo: 'Texto',
                        width: '8rem',
                        marginRight: '0',
                        titulo: `${percent}%`,
                        flexDirection: 'row',
                        botoes: administrador
                            ? [
                                  {
                                      titulo: 'Porcentagem JogoRápido cobra sobre o valor do frete + taxa de retorno',
                                      click: handleEmpresaConfiguracoes,
                                      data: unidade,
                                      icone: 'icon-editar',
                                  },
                              ]
                            : [],
                    },
                    {
                        tipo: 'Texto',
                        width: 'minmax(22rem, 1.5fr)',
                        marginRight: '0',
                        titulo: abreviarNome(unidade?.nomeResponsavel),
                        subtitulo: telefones,
                        descricao: email,
                    },
                    {
                        tipo: 'Texto',
                        width: 'minmax(22rem, 1.5fr)',
                        marginRight: '0',
                        titulo: `${bairro}${enderecoCep}`,
                        subtitulo: `${enderecoLogradouro}${enderecoNumero}${enderecoQuadra}${enderecoLote}`,
                        descricao: `${enderecoComplemento}`,
                    },
                    {
                        tipo: 'Texto',
                        width: '10rem',
                        marginRight: '0',
                        titulo:
                            tipoPagamento[unidade?.tipoPagamento] || 'Ambos',
                        subtitulo:
                            'Saldo R$ ' +
                            (unidade?.saldo?.total || 0).toFixed(2),
                    },
                    {
                        tipo: 'Texto',
                        titulo: inadimplente ? 'Pagamento' : '',
                        subtitulo: inadimplente ? 'pendente' : '',
                        width: '1.5fr',
                        marginRight: '0',
                        flexDirection: 'row',
                        botoes: inadimplente
                            ? [{
                                titulo: 'Editar dados da empresa',
                                click: handleEditarEmpresa,
                                data: unidade,
                                icone: 'icon-editar',
                            },botaoRelatorio]
                            : botoes,
                            botoesInline: [{
                                titulo: !boolBlockColeta ? 'Liberado para receber coleta' : 'Bloqueado para receber coleta',
                                click: handleToogleColeta,
                                data: unidade,
                                icone: boolBlockColeta ? 'icon-deslike' : 'icon-like',
                                color: boolBlockColeta ? 'red' : 'green',
                            }],
                    },
                ] : [
                    {
                        tipo: 'Texto',
                        width: '4rem',
                        marginRight: '0',
                        titulo: unidade?.prefixoPedido,
                    },
                    {
                        tipo: 'Texto',
                        width: '18rem',
                        marginRight: '0',
                        titulo: unidade?.nomeFantasia,
                        subtitulo: unidade?.identificador,
                    },
                    {
                        tipo: 'Texto',
                        width: 'minmax(22rem, 1.5fr)',
                        marginRight: '0',
                        titulo: abreviarNome(unidade?.nomeResponsavel),
                        subtitulo: telefones,
                        descricao: email,
                    },
                    {
                        tipo: 'Texto',
                        width: 'minmax(22rem, 1.5fr)',
                        marginRight: '0',
                        titulo: `${bairro}${enderecoCep}`,
                        subtitulo: `${enderecoLogradouro}${enderecoNumero}`,
                        descricao: `${enderecoComplemento}`,
                    },
                    {
                        tipo: 'Texto',
                        width: '10rem',
                        marginRight: '0',
                        titulo:
                            tipoPagamento[unidade?.tipoPagamento] || 'Ambos',
                        subtitulo:
                            'Saldo R$ ' +
                            (unidade?.saldo?.total || 0).toFixed(2),
                    },
                    {
                        tipo: 'Texto',
                        titulo: inadimplente ? 'Pagamento' : '',
                        subtitulo: inadimplente ? 'pendente' : '',
                        width: '1.5fr',
                        marginRight: '0',
                        flexDirection: 'row',
                        botoes: inadimplente
                            ? [botaoRelatorio]
                            : botoes,
                    },
                ],
            };
        });
    }, [unidades, administrador]);

    return data;
};
