import { useMutation } from '@apollo/client';
import { format, parseISO } from 'date-fns';
import { GRAPHQL_ATUALIZAR_STATUS_COLETA_SALVO, GRAPHQL_DESAGRUPAR_COLETA, GRAPHQL_STATUS_COLETA } from 'gql/coleta';
import { abreviarNome } from 'libs/StringUteis';
import { StatusID } from 'pages/coleta/listar/constants/status-name-2-id';
import { GRAPHQL_ATENCAO_ENTREGADOR } from './../../../../../../gql/entregador';

import { dateTimeline } from 'libs/DataUtils';
import { useCallback, useContext, useRef } from 'react';
import { useModalHistory } from 'routes/admin/hook/useModalHistory';
import { AuthContext } from 'routes/auth.context';
import { IColeta, IStatusColeta } from '../index.d';
import axios from 'axios';
interface IPropsTransformStatus {
    coleta: IColeta;
    permissoes: any;
    permitirAlterarStatus: Boolean;
    timeAdicionalCollectt: number;
    totalEntregadorEscalado: number;
    unidadesAgrupaveis: any;
}
export const useStatus = (permissoes, coletas: IColeta[]) => {
    const { user } = useContext(AuthContext);
    const isAdmin = user?.perfil?.id === 1;
    const history = useModalHistory();
    const lockClick = useRef(false);
    const { alertConfirm } = useContext(AuthContext);
    const [handleDesagruparColeta] = useMutation(GRAPHQL_DESAGRUPAR_COLETA);

    const [handleAtualizaStatusColeta] = useMutation(GRAPHQL_STATUS_COLETA);
    const [handleAtencaoPlayer, { loading }] = useMutation(
        GRAPHQL_ATENCAO_ENTREGADOR,
    );
    const [handleVoltarSalvo, { loading: loadingVoltarSalvo }] = useMutation(
        GRAPHQL_ATUALIZAR_STATUS_COLETA_SALVO,
    );
    const clickAgrupar = useCallback(async ({ coletaId, numeroInterno }) => {
        history.push(`coleta~agrupar/${coletaId}/${numeroInterno}`);
    }, []);

    const clickDetalhes = useCallback(async ({ coletaId }) => {
        history.push(`detalhes/${coletaId}`);
    }, []);

    const clickCliente = useCallback(async ({ clienteId }) => {
        history.push(`cliente/${clienteId}`);
    }, []);
    const clickEditarPagamento = useCallback(async ({ coletaId }) => {
        history.push(`pagamento/${coletaId}`);
    }, []);
    const clickComprovantePagamento = useCallback(async ({ coletaId }) => {
        history.push(`comprovante/${coletaId}`);
    }, []);
    const clickReciboPagamento = useCallback(async ({ coletaId }) => {
        window.open(`${process.env.REACT_APP_HOST_EXPRESS}/invoice/${coletaId}` , '_blank');
    }, []);

    const clickVoltarStatusSalvo = useCallback(async ({ id: coletaId }) => {
        try {
            if (!lockClick.current) {
                lockClick.current = true;
                const result = await alertConfirm({
                    tipo: 'sucesso',
                    titulo: 'Alerta',
                    conteudo:
                        'Deseja voltar a coleta para o status "Salvo"?',
                });
                if(result){
                    await handleVoltarSalvo({ variables: { coletaId } });
                }
            }
            // @ts-ignore
        } catch ({ message }) {
            await alertConfirm({
                tipo: 'erro',
                titulo: 'Alerta',
                conteudo: message as string,
            });
        } finally {
            lockClick.current = false;
        }
    }, []);

    const renderBotaoVoltarSalvo = useCallback((coleta: IColeta, atual: IStatusColeta) => {
        return [
            {
                icone: 'icon-remover2',
                data: coleta,
                status: 'coletabtn',
                titulo: 'Voltar para o status salvo',
                placeholder:
                    'Ao clicar neste botão a coleta voltará para o status salvo',
                click: clickVoltarStatusSalvo,
            }
        ];

    }, []);

    const renderAlterarPagamento = useCallback(
        (coleta: IColeta = {}, perfilId: number) => {
            return [{
                titulo: 'Dados de pagamento',
                click: clickEditarPagamento,
                data: {
                    coletaId: coleta?.id,
                },
                status: 'coletabtn',
                icone: 'icon-cartao',
            }];
        },
        []
    );

    const renderCancelar = useCallback(
        (coleta: IColeta = {}, totalEntregadorEscalado: number, statusId: number) => {
            if (totalEntregadorEscalado > 0) {
                return [{
                    placeholder: 'Cancelar coleta',
                    titulo: 'Cancelar coleta',
                    click: clickStatus,
                    data: {
                        coletaId: coleta.id,
                        statusId: 7,
                        mensagem:
                            'A ação de "Cancelar coleta" é irreversível. Tem certeza que deseja cancelar a coleta?',
                    },
                    status: 'coletabtn',
                    icone: 'icon-fechar',
                }];
            }
            return [];
        },
        []
    );

    const clickDesagrupar = useCallback(
        async ({ id: coletaId }) => {
            try {
                const resultConfirm = await alertConfirm({
                    tipo: 'erro',
                    titulo: 'Confirme',
                    textOk: 'Sim',
                    textCancelar: 'Não',
                    conteudo: 'Tem certeza que deseja desagrupar esta coleta?',
                });
                if (resultConfirm)
                    await handleDesagruparColeta({
                        variables: { coletaId },
                    });
                    // @ts-ignore
            } catch ({ message }) {
                await alertConfirm({
                    tipo: 'erro',
                    titulo: 'Alerta',
                    conteudo: message as string,
                });
            }
        },
        [alertConfirm, handleDesagruparColeta],
    );

    const clickMapa = useCallback(async coleta => {
        history.push(
            `localizacao/${coleta?.unidade?.geoLatitude}/${
                coleta?.unidade?.geoLongitude
            }/${coleta?.cliente?.endereco?.latitude}/${
                coleta?.cliente?.endereco?.longitude
            }/${coleta?.entregador?.id}/${coleta?.status?.[0]?.id || 6}/${coleta?.id}`,
        );
    }, []);

    const clickStatus = useCallback(
        async ({ coletaId, statusId, mensagem }) => {
            try {
                const resultConfirm = await alertConfirm({
                    tipo: 'erro',
                    titulo: 'Confirme',
                    textOk: 'Sim',
                    textCancelar: 'Não',
                    conteudo: mensagem,
                });
                if (resultConfirm)
                    await handleAtualizaStatusColeta({
                        variables: {
                            coletaId,
                            statusId,
                        },
                    });
                    // @ts-ignore
            } catch ({ message }) {
                await alertConfirm({
                    tipo: 'erro',
                    titulo: 'Alerta',
                    conteudo: message as string,
                });
            }
        },
        [alertConfirm, handleAtualizaStatusColeta],
    );
    const addDescricao = useCallback(
        (totalEntregadorEscalado, coletaPaiId) => ({
            descricao:
                (totalEntregadorEscalado > 0
                    ? ''
                    : 'Nenhum entregador escalado'),
        }),
        [permissoes],
    );
    const renderLocalizacao = useCallback((coleta: IColeta) => {
            return [
                {
                    titulo: 'Mapa de localização',
                    placeholder: 'Mapa de localização',
                    click: clickMapa,
                    data: coleta,
                    status: 'coletabtn',
                    icone: 'icon-gps',
                },
            ];
    }, []);
    const renderRecibo = useCallback((coleta: IColeta) => {
            return [
                {
                    titulo: 'Recibo de pagamento',
                    click: clickReciboPagamento,
                    data: {
                        coletaId: coleta?.id,
                    },
                    status: 'coletabtn',
                    icone: 'icon-invoice',
                },
            ];
    }, []);
    const renderDetalhesCorrida = useCallback((coleta: IColeta) => {
            return [
                {
                    click: clickDetalhes,
                    data: {
                        coletaId: coleta?.id,
                    },
                    titulo: `Detalhes da corrida`,
                    status: 'coletabtn',
                    icone: `${
                        coleta.modalTransporteId === 2
                            ? 'icon-carro'
                            : 'icon-moto'
                    }`,
                },
            ];
    }, []);

    const clickAddStatus = useCallback(
        ({ coletaId, coletaPaiId, coletas }) => {
            const response = coletas.find(x => {
                // return coletaPaiId ? x.id === coletaPaiId : x.id === coletaId;
                return x.id === coletaId
            });
            history.push(
                `coleta/adicionar-status-manual/${response?.id}/${response?.entregador?.id}`,
            );
        },
        [],
    );
    // FIXME Analise agora
    const renderAlternanciaStatus = useCallback(
        ({
            permitirAlterarStatus,
            totalEntregadorEscalado,
            statusId,
            coleta: { id: coletaId, coletaPaiId, modalTransporteId },
        }) => {
            const botoes = [];
            if (totalEntregadorEscalado > 0) {
                if (
                    (permissoes.perfilAdministrador &&
                        [StatusID.CONCLUIDO].indexOf(statusId) === -1) ||
                    (!permissoes.perfilAdministrador &&
                        [StatusID.EXPEDINDO].indexOf(statusId) !== -1)
                ) {
                    botoes.push({
                        placeholder: 'Cancelar coleta',
                        click: clickStatus,
                        data: {
                            coletaId,
                            statusId: 7,
                            mensagem:
                                'A ação de "Cancelar coleta" é irreversível. Tem certeza que deseja cancelar a coleta?',
                        },
                        status: 'coletabtn',
                        icone: 'icon-fechar',
                    });
                    if (permissoes.perfilAdministrador) {
                        botoes.push({
                            titulo: 'Adicionar status',
                            placeholder: 'Adicionar status manualmente',
                            click: clickAddStatus,
                            data: {
                                coletaId,
                                coletaPaiId,
                            },
                            status: 'coletabtn',
                            icone: 'icon-adicionar2',
                        });
                    }
                }
                if (permitirAlterarStatus) {
                    if ([StatusID.EXPEDINDO].indexOf(statusId) !== -1) {
                        botoes.push({
                            titulo: 'Chamar o player',
                            placeholder: 'Chamar o player',
                            click: clickStatus,
                            data: {
                                coletaId,
                                statusId: 1,
                                mensagem:
                                    'Tem certeza que deseja colocar esta coleta como "Procurando Player"?',
                            },
                            status: 'coletabtnAtivo',
                            icone: modalTransporteId === 2
                            ? 'icon-carro'
                            : 'icon-moto',
                        });
                    }
                    if (
                        permissoes.perfilAdministrador &&
                        [
                            StatusID.PENDENTE,
                            StatusID.RECEBIDO,
                            StatusID.VISUALIZADO,
                        ].indexOf(statusId) !== -1
                    ) {
                        botoes.push({
                            titulo: 'Coleta expedindo',
                            placeholder: 'Coleta expedindo',
                            click: clickStatus,
                            data: {
                                coletaId,
                                statusId: 8,
                                mensagem:
                                    'Tem certeza que deseja colocar esta coleta como "Salvo"?',
                            },
                            status: 'coletabtn',
                            icone: 'icon-relogio5',
                        });
                    }
                }
            }
            return botoes;
        },
        [permissoes, clickAddStatus, clickStatus],
    );
    const renderAdcionarStatusManualmente = useCallback(
        ({
            totalEntregadorEscalado,
            statusId,
            coleta: { id: coletaId, coletaPaiId },
            permissoes,
            coletas,
        }) => {
            
            const botoes = [];
            if (permissoes.perfilAdministrador && totalEntregadorEscalado > 0) {
                if(statusId !== StatusID.CANCELADO){
                    
                    botoes.push({
                        titulo: 'Status Manual',
                        status: 'coletabtn',
                        placeholder: 'Adicionar status manualmente',
                        click: () => {
                            clickAddStatus({
                                coletaId,
                                coletaPaiId,
                                coletas,
                            });
                        },
                        data: {
                            coletaId,
                            coletaPaiId,
                        },
                        icone: 'icon-adicionar2',
                    });
                }
            }
            return botoes;
        },
        [clickAddStatus, clickStatus],
    );
    const renderChamarPlayer = useCallback(
        ({
            permitirAlterarStatus,
            statusId,
            coleta: { id: coletaId, modalTransporteId },
        }) => {
            const botoes = [];
            if (permitirAlterarStatus) {
                if ([StatusID.EXPEDINDO].indexOf(statusId) !== -1) {
                    botoes.push({
                        titulo: 'Chamar o player',
                        placeholder: 'Chamar o player',
                        status: 'coletabtnAtivo',
                        click: clickStatus,
                        data: {
                            coletaId,
                            statusId: 1,
                            mensagem:
                                'Tem certeza que deseja colocar esta coleta como "Procurando Player"?',
                        },
                        icone: modalTransporteId === 2
                        ? 'icon-carro'
                        : 'icon-moto',
                    });
                }
            }
            return botoes;
        },
        [permissoes, clickAddStatus, clickStatus],
    );
    const renderAgruparColeta = useCallback(
        ({
            coleta,
            agrupavel,
            statusId,
            hasEntregadorEscalado,
            hasPossibilidadeDeAgrupar,
        }) => {
            const botoes = [];
            // if (agrupavel && hasEntregadorEscalado) {
            if (hasEntregadorEscalado) {
                if (coleta.reactGrouped) {
                    if (
                        [StatusID.ACEITO, StatusID.CHECKIN_UNIDADE].indexOf(
                            statusId,
                        ) !== -1
                    ) {
                        botoes.push({
                            status: 'coletabtn',
                            titulo: 'Desagrupar coleta',
                            icone: 'icon-bifurcacao',
                            data: coleta,
                            placeholder: 'Desagrupar coleta',
                            click: clickDesagrupar,
                        });
                    }
                } else if (hasPossibilidadeDeAgrupar) {
                    if (
                        [StatusID.ACEITO, StatusID.CHECKIN_UNIDADE].indexOf(
                            statusId,
                        ) !== -1
                    ) {
                        botoes.push({
                            status: 'coletabtn',
                            titulo: 'Agrupar coleta',
                            icone: 'icon-agrupar',
                            data: {
                                coletaId: coleta.id,
                                numeroInterno: coleta.numeroInterno,
                            },
                            placeholder: 'Agrupar coleta',
                            click: clickAgrupar,
                        });
                    }
                }
            }
            return botoes;
        },
        [permissoes, clickDesagrupar, clickAgrupar],
    );
    const clickOpenListarStatus = useCallback(
        ({ coletaId }) => {
            history.push(`status/listar/${coletaId}`);
        },
        [history, permissoes],
    );
    const clickChamarAtencaoPlayer = useCallback(async ({ id: coletaId }) => {
        try {
            if (!lockClick.current) {
                lockClick.current = true;
                await handleAtencaoPlayer({ variables: { coletaId } });
                await alertConfirm({
                    tipo: 'sucesso',
                    titulo: 'Alerta',
                    conteudo:
                        'Coleta chamada para atenção do entregador. Caso o ele demore para chegar, notifique-o novamente.',
                });
            }
            // @ts-ignore
        } catch ({ message }) {
            await alertConfirm({
                tipo: 'erro',
                titulo: 'Alerta',
                conteudo: message as string,
            });
        } finally {
            lockClick.current = false;
        }
    }, []);
    const renderHistoricoStatus = useCallback(
        (coletaId: string, historico?: IStatusColeta[]) => {
            const botoes = [];
            if (historico.length > 0) {
                const historicoFormatado = historico?.map(status => {
                    return `${format(
                        parseISO(status.dataAlteracao),
                        'HH:mm',
                    )} - ${status.nome} ${
                        status?.nomeCompleto
                            ? ` ( ${abreviarNome(status?.nomeCompleto)} )`
                            : ''
                    }`;
                });

                botoes.push({
                    titulo: 'Histórico',
                    placeholder: ['HISTÓRICO', ...historicoFormatado].join(
                        '<br/>',
                    ),
                    icone: 'icon-ajuda-solid',
                    status: 'coletabtn',
                    data: {
                        coletaId: coletaId,
                    },
                    click: clickOpenListarStatus,
                });
            }
            return botoes;
        },
        [clickOpenListarStatus, permissoes],
    );

    const renderBotoesEntregador = useCallback(
        (coleta: IColeta) => {
            const { qtdxchamouplayer, id: statusId } =
            coleta?.status.length > 0
                ? coleta?.status[0]
                : { qtdxchamouplayer: 0, id: 8 };

            const botoes = [];

            if (isAdmin && statusId !== 6 && coleta?.entregador?.id && coleta?.entregador?.connection !== 'online') {
                botoes.push({
                    titulo: 'Entregador offline',
                    icone: 'icon-modoaviao',
                    color: 'var(--error-cor)',
                });
            }
            return botoes;
        }, [isAdmin]
    );
    const clickEndereco = useCallback(
        async ({ coletaId, clienteId, enderecoId, unidadeId }) => {
            history.push(
                `endereco/${clienteId}/${coletaId}/${enderecoId}/${unidadeId}`
            );
        },
        []
    );
    const clickAceitarNovoEndereco = useCallback(
        async ({ novoEnderecoNaoAprovado, coletaId }) => {
            const {
                clienteEndereco: { id, latitude, longitude },
            } = JSON.parse(novoEnderecoNaoAprovado);

            history.push(
                `cliente-atualizar-localizacao/${id}/${latitude}/${longitude}/${coletaId}`
            );
        },
        []
    );

    const renderBotoesEnderecoEntrega = useCallback((coleta: IColeta) => {
        const endereco = coleta?.cliente?.endereco;
        const hasUpdateAddress = !!endereco?.novoEnderecoNaoAprovado;
        const statusId = (coleta?.status[0] || {}).id;
        const botoes = [];

        if (hasUpdateAddress) {
            botoes.push(
                {
                    titulo: 'Clique aqui para aprovar a solicitação de correção de endereço do cliente',
                    click: clickAceitarNovoEndereco,
                    data: {
                        coletaId: coleta.id,
                        novoEnderecoNaoAprovado:
                            endereco?.novoEnderecoNaoAprovado,
                    },
                    status: 'coletabtn',
                    icone: 'icon-marker-pessoa'
                },
            )
        } else if (
            statusId &&
            [
                StatusID.PENDENTE,
                StatusID.ACEITO,
                StatusID.CHECKIN_UNIDADE,
                StatusID.EXPEDINDO,
                StatusID.PENDENTE,
                StatusID.RECEBIDO,
                StatusID.VISUALIZADO,
            ].indexOf(statusId) !== -1
            && !permissoes.administrador
        ) {
            botoes.push(
                {
                    titulo: 'Endereço da entrega',
                    click: clickEndereco,
                    data: {
                        coletaId: coleta?.id,
                        clienteId: coleta?.cliente?.id,
                        enderecoId: coleta?.cliente?.endereco?.id,
                        unidadeId: coleta?.unidade?.id,
                    },
                    status: 'coletabtn',
                    icone: 'icon-editar',
                },
            );
        }
        return botoes;

    }, [clickEndereco, clickAceitarNovoEndereco]);


    const renderBotaoAvisoSonoro = useCallback((coleta: IColeta, atual: IStatusColeta) => {
        const mostrarBtn = [
            StatusID.ACEITO,
            StatusID.CHECKIN_UNIDADE,
        ].includes(atual.id) && coleta.avisoSonoroLastedStatusId !== atual.id;
        let botoes = [];
        if(mostrarBtn){
            botoes.push({
                icone: 'icon-audio',
                data: coleta,
                status: 'coletabtnAtivo',
                titulo: loading
                    ? 'notificando...'
                    : 'Avisar pedido pronto',
                placeholder:
                    'Ao clicar neste botão o entregador receberá um sinal sonoro informando que o pedido está pronto.',
                click: clickChamarAtencaoPlayer,
            });
        }
        return botoes;

    }, [clickEndereco, clickAceitarNovoEndereco, permissoes]);

    const renderEditarDadosCliente = useCallback((coleta: IColeta) => {
        const botoes = [];
        botoes.push({
            titulo: 'Editar dados do cliente',
            click: clickCliente,
            data: {
                clienteId: coleta?.cliente?.id,
            },
            status: 'coletabtn',
            icone: 'icon-editar',
        });
        return botoes;
    } , [clickAceitarNovoEndereco]);

    const renderSalvo = useCallback(
        ({
            atual,
            dataAlteracao,
            totalEntregadorEscalado,
            coleta,
            historico,
            permitirAlterarStatus,
            unidadesAgrupaveis,
            permissoes,
            user,
            coletas,
        }: {
            atual: IStatusColeta;
            dataAlteracao: string;
            totalEntregadorEscalado: number;
            coleta: IColeta;
            historico: IStatusColeta[];
            permitirAlterarStatus: Boolean;
            unidadesAgrupaveis: any;
            permissoes: any;
            user: IUser;
            coletas: IColeta[];
        }) => {
            const perfilId = user.perfil.id;
            if(atual.id !== StatusID.EXPEDINDO || !atual.id) return null;
            return {
                status: 'ativo',
                tipo: 'Texto',
                width: 'minmax(46rem, 1.5fr)',
                marginRight: '0',
                flexDirection: 'row',
                styleTitulo: {
                    color: atual?.corBackground,
                },
                temaBotao: '2',
                flexWrap: 'nowrap',
                titulo: atual?.nome,
                subtitulo: dataAlteracao,
                ...addDescricao(totalEntregadorEscalado, coleta?.coletaPaiId),
                // cliente
                botoes: !permissoes.administrador ? [
                    ...renderRecibo(coleta),
                    ...renderLocalizacao(coleta),
                    // ...renderDetalhesCorrida(coleta),
                    ...renderBotoesEnderecoEntrega(coleta),
                // administrador
                ] : [
                    ...renderDetalhesCorrida(coleta),
                    ...renderRecibo(coleta),
                    ...renderEditarDadosCliente(coleta),
                    ...renderAlterarPagamento(coleta, user.perfil.id),
                    ...renderBotoesEnderecoEntrega(coleta),
                    ...renderCancelar(coleta, totalEntregadorEscalado, atual.id),
                ],
                // cliente
                botoesInline: !permissoes.administrador ? [
                    ...renderChamarPlayer({
                        permitirAlterarStatus,
                        statusId: atual?.id || StatusID.EXPEDINDO,
                        coleta,
                    }),
                    ...renderAlterarPagamento(coleta, user.perfil.id),
                    ...renderCancelar(coleta, totalEntregadorEscalado, atual.id),
                // administrador
                ] : [
                    ...renderChamarPlayer({
                        permitirAlterarStatus,
                        statusId: atual?.id || StatusID.EXPEDINDO,
                        coleta,
                    }),
                    ...renderAdcionarStatusManualmente({
                        totalEntregadorEscalado,
                        statusId: atual?.id || StatusID.EXPEDINDO,
                        coleta,
                        permissoes,
                        coletas,
                    }),
                    ...renderLocalizacao(coleta),
                ],
            };
        }, [isAdmin, permissoes]
    );
    const renderProcurando = useCallback(
        ({
            atual,
            dataAlteracao,
            totalEntregadorEscalado,
            coleta,
            historico,
            permitirAlterarStatus,
            unidadesAgrupaveis,
            permissoes,
            user,
            coletas,
        }: {
            atual: IStatusColeta;
            dataAlteracao: string;
            totalEntregadorEscalado: number;
            coleta: IColeta;
            historico: IStatusColeta[];
            permitirAlterarStatus: Boolean;
            unidadesAgrupaveis: any;
            permissoes: any;
            user: IUser;
            coletas: IColeta[];
        }) => {
            if((![StatusID.PENDENTE, StatusID.VISUALIZADO, StatusID.RECEBIDO].includes(atual.id)) || !atual.id) return null;
            return {
                status: 'ativo',
                tipo: 'Texto',
                width: 'minmax(46rem, 1.5fr)',
                marginRight: '0',
                flexDirection: 'row',
                styleTitulo: {
                    color: atual?.corBackground,
                },
                temaBotao: '2',
                flexWrap: 'nowrap',
                titulo: atual?.nome,
                subtitulo: dataAlteracao,
                ...addDescricao(totalEntregadorEscalado, coleta?.coletaPaiId),
                // cliente
                botoes: !permissoes.administrador ? [
                    ...renderRecibo(coleta),
                    // ...renderDetalhesCorrida(coleta),
                    ...renderHistoricoStatus(coleta.id, historico),
                // administrador
                ] : [
                    ...renderBotaoVoltarSalvo(coleta, atual),
                    ...renderDetalhesCorrida(coleta),
                    ...renderRecibo(coleta),
                    ...renderEditarDadosCliente(coleta),
                    ...renderAlterarPagamento(coleta, user.perfil.id),
                    ...renderBotoesEnderecoEntrega(coleta),
                    ...renderCancelar(coleta, totalEntregadorEscalado, atual.id),
                    ...renderHistoricoStatus(coleta.id, historico),
                ],
                // cliente
                botoesInline: !permissoes.administrador ? [
                    ...renderAlterarPagamento(coleta, user.perfil.id),
                    ...renderBotoesEnderecoEntrega(coleta),
                    ...renderCancelar(coleta, totalEntregadorEscalado, atual.id),
                // administrador
                ] : [
                    ...renderAgruparColeta({
                            coleta,
                            agrupavel: !!permissoes.agrupavel,
                            statusId: atual.id,
                            hasEntregadorEscalado: totalEntregadorEscalado > 0,
                            hasPossibilidadeDeAgrupar:
                                unidadesAgrupaveis[coleta?.unidade?.id] > 1,
                    }),
                    ...renderAdcionarStatusManualmente({
                        totalEntregadorEscalado,
                        statusId: atual?.id || StatusID.EXPEDINDO,
                        coleta,
                        permissoes,
                        coletas,
                    }),
                    ...renderLocalizacao(coleta),
                ],
            };
        }, [isAdmin]
    );
    const renderAceitoRecebidoVisualizado = useCallback(
        ({
            atual,
            dataAlteracao,
            totalEntregadorEscalado,
            coleta,
            historico,
            permitirAlterarStatus,
            unidadesAgrupaveis,
            permissoes,
            user,
            coletas,
        }: {
            atual: IStatusColeta;
            dataAlteracao: string;
            totalEntregadorEscalado: number;
            coleta: IColeta;
            historico: IStatusColeta[];
            permitirAlterarStatus: Boolean;
            unidadesAgrupaveis: any;
            permissoes: any;
            user: IUser;
            coletas: IColeta[];
        }) => {
            if(atual.id !== StatusID.ACEITO || !atual.id) return null;
            return {
                status: 'ativo',
                tipo: 'Texto',
                width: 'minmax(46rem, 1.5fr)',
                marginRight: '0',
                flexDirection: 'row',
                styleTitulo: {
                    color: atual?.corBackground,
                },
                temaBotao: '2',
                flexWrap: 'nowrap',
                titulo: atual?.nome,
                subtitulo: dataAlteracao,
                ...addDescricao(totalEntregadorEscalado, coleta?.coletaPaiId),
                // cliente
                botoes: !permissoes.administrador ? [
                    ...renderRecibo(coleta),
                    // ...renderDetalhesCorrida(coleta),
                    ...renderBotoesEnderecoEntrega(coleta),
                    ...renderHistoricoStatus(coleta.id, historico),
                // administrador
                ] : [
                    ...renderBotaoVoltarSalvo(coleta, atual),
                    ...renderDetalhesCorrida(coleta),
                    ...renderRecibo(coleta),
                    ...renderEditarDadosCliente(coleta),
                    ...renderAlterarPagamento(coleta, user.perfil.id),
                    ...renderBotoesEnderecoEntrega(coleta),
                    ...renderCancelar(coleta, totalEntregadorEscalado, atual.id),
                    ...renderHistoricoStatus(coleta.id, historico),
                ],
                // cliente
                botoesInline: !permissoes.administrador ? [
                    ...renderBotaoAvisoSonoro(coleta, atual),
                    ...renderAlterarPagamento(coleta, user.perfil.id),
                    ...renderLocalizacao(coleta),
                    ...renderCancelar(coleta, totalEntregadorEscalado, atual.id),
                // administrador
                ] : [
                    ...renderAgruparColeta({
                            coleta,
                            agrupavel: !!permissoes.agrupavel,
                            statusId: atual.id,
                            hasEntregadorEscalado: totalEntregadorEscalado > 0,
                            hasPossibilidadeDeAgrupar:
                                unidadesAgrupaveis[coleta?.unidade?.id] > 1,
                    }),
                    ...renderAdcionarStatusManualmente({
                        totalEntregadorEscalado,
                        statusId: atual?.id || StatusID.EXPEDINDO,
                        coleta,
                        permissoes,
                        coletas,
                    }),
                    ...renderLocalizacao(coleta),
                ],
            };
        }, [isAdmin]
    );
    const renderCheckinUni = useCallback(
        ({
            atual,
            dataAlteracao,
            totalEntregadorEscalado,
            coleta,
            historico,
            permitirAlterarStatus,
            unidadesAgrupaveis,
            permissoes,
            user,
            coletas,
        }: {
            atual: IStatusColeta;
            dataAlteracao: string;
            totalEntregadorEscalado: number;
            coleta: IColeta;
            historico: IStatusColeta[];
            permitirAlterarStatus: Boolean;
            unidadesAgrupaveis: any;
            permissoes: any;
            user: IUser;
            coletas: IColeta[];
        }) => {
            if((![StatusID.CHECKIN_UNIDADE].includes(atual.id)) || !atual.id) return null;
            return {
                status: 'ativo',
                tipo: 'Texto',
                width: 'minmax(46rem, 1.5fr)',
                marginRight: '0',
                flexDirection: 'row',
                styleTitulo: {
                    color: atual?.corBackground,
                },
                temaBotao: '2',
                flexWrap: 'nowrap',
                titulo: atual?.nome,
                subtitulo: dataAlteracao,
                ...addDescricao(totalEntregadorEscalado, coleta?.coletaPaiId),
                // cliente
                botoes: !permissoes.administrador ? [
                    ...renderRecibo(coleta),
                    // ...renderDetalhesCorrida(coleta),
                    ...renderHistoricoStatus(coleta.id, historico),
                // administrador
                ] : [
                    ...renderBotaoVoltarSalvo(coleta, atual),
                    ...renderDetalhesCorrida(coleta),
                    ...renderRecibo(coleta),
                    ...renderEditarDadosCliente(coleta),
                    ...renderAlterarPagamento(coleta, user.perfil.id),
                    ...renderBotoesEnderecoEntrega(coleta),
                    ...renderCancelar(coleta, totalEntregadorEscalado, atual.id),
                    ...renderHistoricoStatus(coleta.id, historico),
                ],
                // cliente
                botoesInline: !permissoes.administrador ? [
                    ...renderBotaoAvisoSonoro(coleta, atual),
                    ...renderLocalizacao(coleta),
                    ...renderAlterarPagamento(coleta, user.perfil.id),
                    ...renderBotoesEnderecoEntrega(coleta),
                // administrador
                ] : [
                    ...renderAgruparColeta({
                            coleta,
                            agrupavel: !!permissoes.agrupavel,
                            statusId: atual.id,
                            hasEntregadorEscalado: totalEntregadorEscalado > 0,
                            hasPossibilidadeDeAgrupar:
                                unidadesAgrupaveis[coleta?.unidade?.id] > 1,
                    }),
                    ...renderAdcionarStatusManualmente({
                        totalEntregadorEscalado,
                        statusId: atual?.id || StatusID.EXPEDINDO,
                        coleta,
                        permissoes,
                        coletas,
                    }),
                    ...renderLocalizacao(coleta),
                ],
            };
        }, [isAdmin]
    );
    const renderCheckOutUni = useCallback(
        ({
            atual,
            dataAlteracao,
            totalEntregadorEscalado,
            coleta,
            historico,
            permitirAlterarStatus,
            unidadesAgrupaveis,
            permissoes,
            user,
            coletas,
        }: {
            atual: IStatusColeta;
            dataAlteracao: string;
            totalEntregadorEscalado: number;
            coleta: IColeta;
            historico: IStatusColeta[];
            permitirAlterarStatus: Boolean;
            unidadesAgrupaveis: any;
            permissoes: any;
            user: IUser;
            coletas: IColeta[];
        }) => {
            if((![StatusID.CHECKOUT_UNIDADE].includes(atual.id)) || !atual.id) return null;
            return {
                status: 'ativo',
                tipo: 'Texto',
                width: 'minmax(46rem, 1.5fr)',
                marginRight: '0',
                flexDirection: 'row',
                styleTitulo: {
                    color: atual?.corBackground,
                },
                temaBotao: '2',
                flexWrap: 'nowrap',
                titulo: atual?.nome,
                subtitulo: dataAlteracao,
                ...addDescricao(totalEntregadorEscalado, coleta?.coletaPaiId),
                // cliente
                botoes: !permissoes.administrador ? [
                    ...renderRecibo(coleta),
                // administrador
                ] : [
                    ...renderBotaoVoltarSalvo(coleta, atual),
                    ...renderDetalhesCorrida(coleta),
                    ...renderRecibo(coleta),
                    ...renderEditarDadosCliente(coleta),
                    ...renderAlterarPagamento(coleta, user.perfil.id),
                    ...renderBotoesEnderecoEntrega(coleta),
                    ...renderCancelar(coleta, totalEntregadorEscalado, atual.id),
                    ...renderHistoricoStatus(coleta.id, historico),
                ],
                // cliente
                botoesInline: !permissoes.administrador ? [
                    ...renderLocalizacao(coleta),
                    ...renderHistoricoStatus(coleta.id, historico),
                // administrador
                ] : [
                    ...renderAdcionarStatusManualmente({
                        totalEntregadorEscalado,
                        statusId: atual?.id || StatusID.EXPEDINDO,
                        coleta,
                        permissoes,
                        coletas,
                    }),
                    ...renderLocalizacao(coleta),
                ],
            };
        }, [isAdmin]
    );
    const renderRetornoEstabelecimento = useCallback(
        ({
            atual,
            dataAlteracao,
            totalEntregadorEscalado,
            coleta,
            historico,
            permitirAlterarStatus,
            unidadesAgrupaveis,
            permissoes,
            user,
            coletas,
        }: {
            atual: IStatusColeta;
            dataAlteracao: string;
            totalEntregadorEscalado: number;
            coleta: IColeta;
            historico: IStatusColeta[];
            permitirAlterarStatus: Boolean;
            unidadesAgrupaveis: any;
            permissoes: any;
            user: IUser;
            coletas: IColeta[];
        }) => {
            if((![StatusID.RETORNO_ESTABELECIMENTO].includes(atual.id)) || !atual.id) return null;
            return {
                status: 'ativo',
                tipo: 'Texto',
                width: 'minmax(46rem, 1.5fr)',
                marginRight: '0',
                flexDirection: 'row',
                styleTitulo: {
                    color: atual?.corBackground,
                },
                temaBotao: '2',
                flexWrap: 'nowrap',
                titulo: atual?.nome,
                subtitulo: dataAlteracao,
                ...addDescricao(totalEntregadorEscalado, coleta?.coletaPaiId),
                // cliente
                botoes: !permissoes.administrador ? [
                    ...renderRecibo(coleta),
                // administrador
                ] : [
                    ...renderBotaoVoltarSalvo(coleta, atual),
                    ...renderDetalhesCorrida(coleta),
                    ...renderRecibo(coleta),
                    ...renderEditarDadosCliente(coleta),
                    ...renderAlterarPagamento(coleta, user.perfil.id),
                    ...renderBotoesEnderecoEntrega(coleta),
                    ...renderCancelar(coleta, totalEntregadorEscalado, atual.id),
                    ...renderHistoricoStatus(coleta.id, historico),
                ],
                // cliente
                botoesInline: !permissoes.administrador ? [
                    ...renderHistoricoStatus(coleta.id, historico),
                    ...renderLocalizacao(coleta),
                // administrador
                ] : [
                    ...renderAdcionarStatusManualmente({
                        totalEntregadorEscalado,
                        statusId: atual?.id || StatusID.EXPEDINDO,
                        coleta,
                        permissoes,
                        coletas,
                    }),
                    ...renderLocalizacao(coleta),
                ],
            };
        }, [isAdmin]
    );
    const renderCheckinCliente = useCallback(
        ({
            atual,
            dataAlteracao,
            totalEntregadorEscalado,
            coleta,
            historico,
            permitirAlterarStatus,
            unidadesAgrupaveis,
            permissoes,
            user,
            coletas,
        }: {
            atual: IStatusColeta;
            dataAlteracao: string;
            totalEntregadorEscalado: number;
            coleta: IColeta;
            historico: IStatusColeta[];
            permitirAlterarStatus: Boolean;
            unidadesAgrupaveis: any;
            permissoes: any;
            user: IUser;
            coletas: IColeta[];
        }) => {
            if((![StatusID.CHECKIN_CLIENTE].includes(atual.id)) || !atual.id) return null;
            return {
                status: 'ativo',
                tipo: 'Texto',
                width: 'minmax(46rem, 1.5fr)',
                marginRight: '0',
                flexDirection: 'row',
                styleTitulo: {
                    color: atual?.corBackground,
                },
                temaBotao: '2',
                flexWrap: 'nowrap',
                titulo: atual?.nome,
                subtitulo: dataAlteracao,
                ...addDescricao(totalEntregadorEscalado, coleta?.coletaPaiId),
                // cliente
                botoes: !permissoes.administrador ? [
                    ...renderRecibo(coleta),
                // administrador
                ] : [
                    ...renderBotaoVoltarSalvo(coleta, atual),
                    ...renderDetalhesCorrida(coleta),
                    ...renderRecibo(coleta),
                    ...renderEditarDadosCliente(coleta),
                    ...renderAlterarPagamento(coleta, user.perfil.id),
                    ...renderBotoesEnderecoEntrega(coleta),
                    ...renderCancelar(coleta, totalEntregadorEscalado, atual.id),
                    ...renderHistoricoStatus(coleta.id, historico),
                ],
                // cliente
                botoesInline: !permissoes.administrador ? [
                    ...renderLocalizacao(coleta),
                    ...renderHistoricoStatus(coleta.id, historico),
                // administrador
                ] : [
                    ...renderAdcionarStatusManualmente({
                        totalEntregadorEscalado,
                        statusId: atual?.id || StatusID.EXPEDINDO,
                        coleta,
                        permissoes,
                        coletas,
                    }),
                    ...renderLocalizacao(coleta),
                ],
            };
        }, [isAdmin]
    );
    const renderCancelarColeta = useCallback(
        ({
            atual,
            dataAlteracao,
            totalEntregadorEscalado,
            coleta,
            historico,
            permitirAlterarStatus,
            unidadesAgrupaveis,
            permissoes,
            user,
            coletas,
        }: {
            atual: IStatusColeta;
            dataAlteracao: string;
            totalEntregadorEscalado: number;
            coleta: IColeta;
            historico: IStatusColeta[];
            permitirAlterarStatus: Boolean;
            unidadesAgrupaveis: any;
            permissoes: any;
            user: IUser;
            coletas: IColeta[];
        }) => {
            if((![StatusID.CANCELADO].includes(atual.id)) || !atual.id) return null;
            return {
                status: 'ativo',
                tipo: 'Texto',
                width: 'minmax(46rem, 1.5fr)',
                marginRight: '0',
                flexDirection: 'row',
                styleTitulo: {
                    color: atual?.corBackground,
                },
                temaBotao: '2',
                flexWrap: 'nowrap',
                titulo: atual?.nome,
                subtitulo: dataAlteracao,
                ...addDescricao(totalEntregadorEscalado, coleta?.coletaPaiId),
                // cliente
                botoes: !permissoes.administrador ? [
                    ...renderRecibo(coleta),
                // administrador
                ] : [
                    ...renderBotaoVoltarSalvo(coleta, atual),
                    ...renderDetalhesCorrida(coleta),
                    ...renderRecibo(coleta),
                    ...renderEditarDadosCliente(coleta),
                    ...renderBotoesEnderecoEntrega(coleta),

                ],
                // cliente
                botoesInline: !permissoes.administrador ? [
                    ...renderHistoricoStatus(coleta.id, historico),

                // administrador
                ] : [
                    ...renderAdcionarStatusManualmente({
                        totalEntregadorEscalado,
                        statusId: atual?.id || StatusID.EXPEDINDO,
                        coleta,
                        permissoes,
                        coletas,
                    }),
                    ...renderLocalizacao(coleta),
                    ...renderHistoricoStatus(coleta.id, historico),
                ],
            };
        }, [isAdmin]
    );
    const renderConcluido = useCallback(
        ({
            atual,
            dataAlteracao,
            totalEntregadorEscalado,
            coleta,
            historico,
            permitirAlterarStatus,
            unidadesAgrupaveis,
            permissoes,
            user,
            coletas,
        }: {
            atual: IStatusColeta;
            dataAlteracao: string;
            totalEntregadorEscalado: number;
            coleta: IColeta;
            historico: IStatusColeta[];
            permitirAlterarStatus: Boolean;
            unidadesAgrupaveis: any;
            permissoes: any;
            user: IUser;
            coletas: IColeta[];
        }) => {
            if((![StatusID.CONCLUIDO].includes(atual.id)) || !atual.id) return null;
            return {
                status: 'ativo',
                tipo: 'Texto',
                width: 'minmax(46rem, 1.5fr)',
                marginRight: '0',
                flexDirection: 'row',
                styleTitulo: {
                    color: atual?.corBackground,
                },
                temaBotao: '2',
                flexWrap: 'nowrap',
                titulo: atual?.nome,
                subtitulo: dataAlteracao,
                ...addDescricao(totalEntregadorEscalado, coleta?.coletaPaiId),
                // cliente
                botoes: !permissoes.administrador ? [
                    ...renderRecibo(coleta),
                // administrador
                ] : [
                    ...renderBotaoVoltarSalvo(coleta, atual),
                    ...renderDetalhesCorrida(coleta),
                    ...renderRecibo(coleta),
                    ...renderEditarDadosCliente(coleta),
                    ...renderBotoesEnderecoEntrega(coleta),
                    ...renderCancelar(coleta, totalEntregadorEscalado, atual.id),
                ],
                // cliente
                botoesInline: !permissoes.administrador ? [
                    ...renderHistoricoStatus(coleta.id, historico),
                // administrador
                ] : [
                    ...renderAdcionarStatusManualmente({
                        totalEntregadorEscalado,
                        statusId: atual?.id || StatusID.EXPEDINDO,
                        coleta,
                        permissoes,
                        coletas,
                    }),
                    ...renderLocalizacao(coleta),
                    ...renderHistoricoStatus(coleta.id, historico),
                ],
            };
        }, [isAdmin]
    );

    const transformStatus = useCallback(
        ({
            coleta,
            permissoes,
            permitirAlterarStatus,
            totalEntregadorEscalado,
            unidadesAgrupaveis,
        }: IPropsTransformStatus) => {
            const [atual, ...historico] =
                coleta?.status && coleta?.status.length > 0
                    ? coleta?.status
                    : [{}];

            const mostrarTempo = [
                StatusID.ACEITO,
                StatusID.CHECKIN_UNIDADE,
                StatusID.CHECKOUT_UNIDADE,
                StatusID.CHECKIN_CLIENTE,
                StatusID.RECEBIDO,
                StatusID.VISUALIZADO,
            ].includes(atual.id);

            const mostrarData = [
                StatusID.CONCLUIDO,
                StatusID.CANCELADO,
            ].includes(atual.id);
            const diffTime = dateTimeline(
                new Date(atual.dataAlteracao),
                new Date(),
            );
            const timeDiff =
                permissoes.administrador && mostrarTempo && diffTime
                    ? `(${diffTime})`
                    : '';

            const endereco = coleta?.cliente?.endereco;
            const hasUpdateAddress = !!endereco?.novoEnderecoNaoAprovado;
            const dataAlteracao = atual?.dataAlteracao
                ? `${format(
                      parseISO(atual?.dataAlteracao),
                      mostrarData ? 'dd/MM/yyyy HH:mm' : 'HH:mm',
                  )} ${timeDiff}`
                : 'Sem status';
                return {
                    ...renderSalvo({
                        coleta,
                        atual,
                        totalEntregadorEscalado,
                        unidadesAgrupaveis,
                        historico,
                        permitirAlterarStatus,
                        dataAlteracao,
                        permissoes,
                        user,
                        coletas,
                    }),
                    ...renderProcurando({
                        coleta,
                        atual,
                        totalEntregadorEscalado,
                        unidadesAgrupaveis,
                        historico,
                        permitirAlterarStatus,
                        dataAlteracao,
                        permissoes,
                        user,
                        coletas,
                    }),
                    ...renderAceitoRecebidoVisualizado({
                        coleta,
                        atual,
                        totalEntregadorEscalado,
                        unidadesAgrupaveis,
                        historico,
                        permitirAlterarStatus,
                        dataAlteracao,
                        permissoes,
                        user,
                        coletas,
                    }),
                    ...renderCheckinUni({
                        coleta,
                        atual,
                        totalEntregadorEscalado,
                        unidadesAgrupaveis,
                        historico,
                        permitirAlterarStatus,
                        dataAlteracao,
                        permissoes,
                        user,
                        coletas,
                    }),
                    ...renderCheckOutUni({
                        coleta,
                        atual,
                        totalEntregadorEscalado,
                        unidadesAgrupaveis,
                        historico,
                        permitirAlterarStatus,
                        dataAlteracao,
                        permissoes,
                        user,
                        coletas,
                    }),
                    ...renderCheckinCliente({
                        coleta,
                        atual,
                        totalEntregadorEscalado,
                        unidadesAgrupaveis,
                        historico,
                        permitirAlterarStatus,
                        dataAlteracao,
                        permissoes,
                        user,
                        coletas,
                    }),
                    ...renderRetornoEstabelecimento({
                        coleta,
                        atual,
                        totalEntregadorEscalado,
                        unidadesAgrupaveis,
                        historico,
                        permitirAlterarStatus,
                        dataAlteracao,
                        permissoes,
                        user,
                        coletas,
                    }),
                    ...renderCancelarColeta({
                        coleta,
                        atual,
                        totalEntregadorEscalado,
                        unidadesAgrupaveis,
                        historico,
                        permitirAlterarStatus,
                        dataAlteracao,
                        permissoes,
                        user,
                        coletas,
                    }),
                    ...renderConcluido({
                        coleta,
                        atual,
                        totalEntregadorEscalado,
                        unidadesAgrupaveis,
                        historico,
                        permitirAlterarStatus,
                        dataAlteracao,
                        permissoes,
                        user,
                        coletas,
                    }),
                }
                ;
        },
        [
            loading,
            addDescricao,
            renderHistoricoStatus,
            renderLocalizacao,
            renderAgruparColeta,
            renderAlternanciaStatus,
            renderBotoesEntregador,
            renderBotoesEnderecoEntrega,
            permissoes,
        ],
    );

    return { transformStatus, loading };
};
