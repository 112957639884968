const formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2,
});
export const formatarMoeda = (valor) => {
    const nValue = valor && Number(valor) >= 0 ? valor : 0;
    return formatter.format(nValue).replace('R$', '').trim();
};

export const calculaValorFreteComTaxa = ({
    frete,
    porcentagemNegociacao,
    distanciaMinima,
    distancia,
    adcionalRetorno,
    txMinRetorno,
    mostrarValorTotal,
    valorAdicionalCorrida
}:{
    frete: number;
    porcentagemNegociacao: number;
    distanciaMinima?: number;
    distancia?: number;
    adcionalRetorno?: number; // valorKmRetorno
    txMinRetorno?: number;
    mostrarValorTotal?: boolean;
    valorAdicionalCorrida?: number;
}): { valorFreteComRetorno: number; valorFreteSemRetorno: number; } => {
    let tmp = {
        valorFreteComRetorno: 0,
        valorFreteSemRetorno: 0,
    };
    if (frete) {
        const valorRetorno = ((((distancia - distanciaMinima) * adcionalRetorno + txMinRetorno)));
        if((distancia <= distanciaMinima) || (distancia === undefined) || (distanciaMinima === undefined)){
            if(!mostrarValorTotal){
                const valorTotalComTaxaRetorno =
                    frete +
                    txMinRetorno;
                const valorTotalSemTaxaRetorno =
                    frete;
                tmp.valorFreteSemRetorno = valorTotalSemTaxaRetorno;
                tmp.valorFreteComRetorno = valorTotalComTaxaRetorno;
                if(valorAdicionalCorrida){
                    tmp.valorFreteComRetorno += valorAdicionalCorrida;
                    tmp.valorFreteSemRetorno += valorAdicionalCorrida;
                }
            } else {
                const valorTotalComTaxaRetorno =
                    frete +
                    txMinRetorno +
                    (frete + txMinRetorno) *
                        porcentagemNegociacao;
                const valorTotalSemTaxaRetorno =
                    frete +
                    frete * porcentagemNegociacao;
                tmp.valorFreteSemRetorno = valorTotalSemTaxaRetorno;
                tmp.valorFreteComRetorno = valorTotalComTaxaRetorno;
                if(valorAdicionalCorrida){
                    tmp.valorFreteComRetorno += valorAdicionalCorrida;
                    tmp.valorFreteSemRetorno += valorAdicionalCorrida;
                }
            }
        } else {
            if(!mostrarValorTotal){
                const valorTotalComTaxaRetorno =
                    frete +
                    valorRetorno;
                const valorTotalSemTaxaRetorno =
                    frete;
                tmp.valorFreteSemRetorno = valorTotalSemTaxaRetorno;
                tmp.valorFreteComRetorno = valorTotalComTaxaRetorno;
                if(valorAdicionalCorrida){
                    tmp.valorFreteComRetorno += valorAdicionalCorrida;
                    tmp.valorFreteSemRetorno += valorAdicionalCorrida;
                }
            } else {
                const valorTotalComTaxaRetorno =
                    frete +
                    valorRetorno +
                    (frete + valorRetorno) *
                        porcentagemNegociacao;
                const valorTotalSemTaxaRetorno =
                    frete +
                    frete * porcentagemNegociacao;
                tmp.valorFreteSemRetorno = valorTotalSemTaxaRetorno;
                tmp.valorFreteComRetorno = valorTotalComTaxaRetorno;
                if(valorAdicionalCorrida){
                    tmp.valorFreteComRetorno += valorAdicionalCorrida;
                    tmp.valorFreteSemRetorno += valorAdicionalCorrida;
                }
            }
        }
    }
    return tmp;
}

export const calculaValorFreteComTaxaDetalhes = ({
    frete,
    porcentagemNegociacao,
    distanciaMinima,
    distancia,
    adcionalRetorno,
    txMinRetorno,
    mostrarValorTotal,
    valorAdicionalCorrida
}:{
    frete: number;
    porcentagemNegociacao: number;
    distanciaMinima?: number;
    distancia?: number;
    adcionalRetorno?: number; // valorKmRetorno
    txMinRetorno?: number;
    mostrarValorTotal?: boolean;
    valorAdicionalCorrida?: number;
}): { valorFreteComRetorno: number; valorFreteSemRetorno: number; } => {
    return calculaValorFreteComTaxa({
        frete,
        porcentagemNegociacao,
        distanciaMinima,
        distancia,
        adcionalRetorno,
        txMinRetorno,
        mostrarValorTotal,
        valorAdicionalCorrida
    });
}
