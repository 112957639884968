import { useState } from 'react';
import { GRAPHQL_CONECTAR } from 'gql/signin';
import { useMutation } from '@apollo/client';
import { useApolloClient } from '@apollo/client';

export const useSigninAndOut = (
    alertConfirm,
    loadDefaultData,
    setCacheFiltroData,
    handleUpdateBearer
) => {
    const [user, setUser] = useState<Omit<IUser, 'token'>>({});
    const client = useApolloClient();
    const [handleSignin, { loading }] =
        useMutation<{
            usuarioConectar?: IUser;
        }>(GRAPHQL_CONECTAR);

    async function signIn(basic) {
        try {
            const { data } = await handleSignin({
                variables: {
                    basic,
                },
            });

            const { email, token, ...rest } = data?.usuarioConectar;
            if (token) {
                localStorage.setItem('@auth/email', email);
                handleUpdateBearer(token);
                setCacheFiltroData(new Date());
                await loadDefaultData();
                setUser({ email, ...rest });
            } else {
                throw new Error('Falha na autenticação do usuário');
            }
        } catch ({ message }) {
            alertConfirm({
                titulo: 'Erro',
                tipo: 'erro',
                conteudo: message,
            });
        }
    }

    // Desconectar o usuário que já está logado
    async function signOut() {
        client.clearStore();
        localStorage.removeItem('@auth/filtroData');
        localStorage.removeItem('@oUnidade/id');
        setCacheFiltroData(new Date());
        handleUpdateBearer(null);
        setUser({});
    }
    return { loading, signIn, signOut, user, setUser };
};
