import { format } from 'date-fns';
import { useCallback, useContext, useState } from 'react';
import _ from 'lodash';
import { unstable_batchedUpdates } from 'react-dom';
import { AuthContext } from 'routes/auth.context';
import { IRetriever } from './index.d';
import { IColeta } from '../../../../../pages/coleta/listar/hooks/useTransformColeta/index.d';
// { dataKeyName: 'dataPedido' }
export const usePaginationAndFilter = (
    props = { 
        dataKeyName: null, 
        keysQueryVariavel: [], 
        callbackHandleSearch: null 
    } as any
): IRetriever => {
    const { cacheFiltroData } = useContext(AuthContext);
    const [pagina, setPagina] = useState(0);
    const [loading, setLoading] = useState(true);
    const [hasMore, setHasMore] = useState(false);
    const [filter, setFilter] = useState({
        ...(props.dataKeyName
            ? { [props.dataKeyName]: format(cacheFiltroData, 'yyyy-MM-dd') }
            : {}),
    });

    const [response, setResponse] = useState([]);

    const onlyUnique = useCallback(
        (value, index, self) => {
            const indice = self.findIndex(({ id }, index2) => {
                return index > index2 && id === value.id;
            });
            return indice === -1;
        },
        []
    );

    const handleSortPaiFirst = useCallback(
        (prev: IColeta, next: IColeta) => {
            if (prev.reactIsPai > next.reactIsPai) {
                return -1;
            }
            if (prev.reactIsPai < next.reactIsPai) {
                return 1;
            }
            return 0;
        },
        []
    );

    const handleSetResponse = useCallback((data) => {
        setResponse(data);
    }, []);
    const unionList = useCallback(
        (prev, next, resetPageZero = true) => {
            const unidadesAgrupaveis = {};
            // prev = prev
            // ?.filter(onlyUnique) // remove coletas duplicadas
            // ?.map((coleta) => groupUnit(coleta, unidadesAgrupaveis, prev))
            if (pagina === 0 && resetPageZero) return next;
            const prevIds = {};
            prev.forEach(({ id }, key) => (prevIds[id] = key));
            let clone = [...prev];
            next.forEach(({ id, ...rest }) => {
                if (prevIds[id] >= 0) {
                    clone[prevIds[id]] = { id, ...clone[prevIds[id]], ...rest };
                } else {
                    clone.push({ id, ...rest });
                }
            });
            // clone = clone
            // ?.filter(onlyUnique) // remove coletas duplicadas
            // clone = _.orderBy(clone, [function(item) {
            //     return item.status[0].ordem
            // }], ['asc']);
            return clone;
        },
        [pagina]
    );
    const onSubscriptionData = useCallback(
        (data, transformData = undefined) => {
            if (data && data.id) {
                setResponse((prev) => {
                    return transformData
                        ? transformData({ prevList: prev, nextChild: data })
                        : unionList(prev, [data], false);
                });
            }
        },
        [pagina]
    );

    const onCompleted = useCallback(
        (data, hasUnion = true) => {
            unstable_batchedUpdates(() => {
                setHasMore((data && data.hasNextPage) || false);
                setResponse((prev) =>
                    hasUnion
                        ? unionList(prev, data.data)
                        : (data && data.data) || []
                );
            });
        },
        [pagina]
    );

    const handleSearch = useCallback((data: any = {}) => {
        const clone = { ...data };
        if (clone && clone.dataSelecionada) {
            if (props.dataKeyName) {
                clone[props.dataKeyName] =
                    typeof clone.dataSelecionada === 'string'
                        ? clone.dataSelecionada
                        : format(clone.dataSelecionada, 'yyyy-MM-dd');
            }

            delete clone.dataSelecionada;
        }
        const result = Object.fromEntries(
            Object.entries(clone).filter(
                ([key, value]) =>
                    [...props.keysQueryVariavel].includes(key) && !!value
            )
        );
       
        setFilter(
            result as {
                [k: string]: string;
            }
        );
        setPagina(0);
        if(props.callbackHandleSearch)
            props.callbackHandleSearch(result);
    }, []);

    const onError = useCallback((err) => {
        console.error(err);
    }, []);

    const onPagination: any = useCallback(() => {
        if (!loading && hasMore) {
            setPagina((prev) => prev + 1);
        }
    }, [loading, hasMore]);

    return {
        setLoading,
        loading,
        onError,
        onPagination,
        response,
        hasMore,
        handleSearch,
        variables: { filter },
        handleSetResponse,
        onSubscriptionData,
        onCompleted,
        context: {
            headers: {
                'current-page': `${pagina}`,
            },
        },
    };
};
