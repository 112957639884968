import { useQuery, useSubscription } from '@apollo/client';
import { Form } from '@unform/web';
import ButtonLoading from 'components/atom/ButtonLoading';
import {
    Map,
    TileLayer,
    Marker,
    // PropTypes as MapPropTypes
  } from "react-leaflet";
import 'leaflet/dist/leaflet.css';
import {
    CLIENTE_SUBSCRIPTION,
    GRAPHQL_BUSCAR_ENDERECO_CLIENTE,
} from 'gql/cliente';
import React, {
    FC,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { AuthContext } from 'routes/auth.context';
import {
    IDataClienteQueryCliente,
    IEnderecoQueryCliente,
    INovoEndereco,
} from 'types/queryCliente';
import ComponentSelecionarEndereco from './Component';
import { useDataEndereco } from './partial/hooks/useDataEndereco';
import { ContainerForm, stylMap, ContainerBody, GoogleMapWrap } from './styl';
import { IClienteEndereco } from 'types/modules/clienteEndereco';
import { ICidade } from 'types/modules/cidade';
import { AtomFreteMapa } from 'components/atom/map/AtomFreteMapa';
import { metroHumanize } from 'libs/GeolocationUtils';
import { calculaValorFreteComTaxa, formatarMoeda } from 'libs/NumberUtils';
import L from 'leaflet';

const novoEndereco: INovoEndereco = {
    titulo: 'Endereço diferente',
    id: 'novo-endereco',
    subtitulo:
        'Caso o cliente queira receber seu pedido em outro endereço clique aqui.',
};
interface ISelecionarEndereco {
    clienteId: string;
    unidadeId?: string;
    endereco_id_selecionado?: string;
    handleSubmit: any;
    rForm: any;
    labelSubmit?: string;
    setHasNewAddress?: React.Dispatch<React.SetStateAction<boolean>>;
    handleEditAddress?: (
        item: IClienteEndereco & {
            clienteId: string;
            cidade: ICidade & {
                uf: {
                    uf: string;
                };
            };
        },
    ) => void;
    handleNavigateForms?: any;
}
export const SelecionarEndereco: FC<ISelecionarEndereco> = ({
    unidadeId,
    ...oProps
}) => {
    const [latLng, setLatLng] = useState({
        lat: 0,
        lng: 0,
    });
    const [enderecoSelecionado, setEnderecoSelecionado] =
        useState<IEnderecoQueryCliente>();
    const { unidadeSelecionada, configuracao, modalTransporte } =
        useContext(AuthContext);
    if (!unidadeId) {
        unidadeId = unidadeSelecionada?.id ? unidadeSelecionada?.id : null;
    }
    const [enderecos, setEnderecos] = useState<IEnderecoQueryCliente[]>([]);
    const { data: dataFetch } = useQuery<IDataClienteQueryCliente>(
        GRAPHQL_BUSCAR_ENDERECO_CLIENTE,
        {
            fetchPolicy: 'no-cache',
            variables: {
                filter: {
                    unidadeId,
                    id: oProps.clienteId,
                },
            },
            onCompleted: ({ cliente: [{ enderecos }] }) => {
                setEnderecos(enderecos);
            },
        },
    );

    // trata a subscription de cliente
    useSubscription(CLIENTE_SUBSCRIPTION, {
        variables: {
            filter: {
                ...(unidadeSelecionada?.id
                    ? { unidadeId: [unidadeSelecionada?.id] }
                    : null),
            },
        },
        onSubscriptionData({
            subscriptionData: {
                data: {
                    cliente: { action, data },
                },
            },
        }) {
            setEnderecos(data.enderecos);
        },
    });

    const { filterData } = useDataEndereco({
        handleNavigateForms: oProps.handleNavigateForms,
        setHasNewAddress: oProps.setHasNewAddress,
        handleEditAddress: oProps.handleEditAddress,
    });
    const loop = enderecos ? [...enderecos, novoEndereco] : [novoEndereco];

    const defaultValue = enderecos?.find(
        ({ id }) =>
            id ===
            Number(`${oProps.endereco_id_selecionado}`.replace(/\D/g, '')),
    )?.id;

    const checkEndereco = useCallback(
        (data: { latitude: number; longitude: number; id?: number }) => {
            if (data.id) {
                const enderecoId = data.id;
                const enderecoSelecionado = enderecos?.find(
                    ({ id }) => id + '' === enderecoId + '',
                );
                setEnderecoSelecionado(enderecoSelecionado);
                setLatLng({
                    lat: data.latitude,
                    lng: data.longitude,
                });
            } else {
                setEnderecoSelecionado(null);
            }
        },
        [enderecos],
    );

    useEffect(() => {
        const initialEndereco = enderecos?.find(
            ({ id }) => id === defaultValue,
        ) as IEnderecoQueryCliente & {
            latitude: number;
            longitude: number;
        };
        setLatLng({
            lat: initialEndereco?.latitude,
            lng: initialEndereco?.longitude,
        });
        setEnderecoSelecionado(initialEndereco);
    }, [defaultValue]);

    const props = {
        filterData,
        enderecos: loop,
        clienteId: oProps.clienteId,
        defaultValue,
        checkEndereco,
    };
    const handleSubmit = useCallback(
        (data, actions) => {
            const {
                endereco: [sEndereco],
            } = data;

            const nEndereco = Number(sEndereco.replace(/[^0-9]/gi, ''));
            const currentAddress =
                enderecos.find(({ id }) => id === nEndereco) || {};

            if (oProps.handleSubmit) {
                oProps.handleSubmit(
                    {
                        ...currentAddress,
                        ...data,
                        cartoes: dataFetch.recuperarFormaPagamento.map(
                            ({ id, exigeTaxaRetorno, nome }) => ({
                                label: nome,
                                value: { id, exigeTaxaRetorno },
                            }),
                        ),
                    },
                    actions,
                );
            }
        },
        [dataFetch, enderecos],
    );
    const distanciaEFrete = useMemo(() => {
        let tmp = {
            distanciaKM: null,
            precisaoKM: null,
            precisao: null,
            valorFrete: null,
            duracao: null,
            valorFreteComRetorno: null,
            valorFreteSemRetorno: null,
        };
        if (enderecoSelecionado) {
            const { frete, distancia, precisao, txRetorno } =
                enderecoSelecionado.frete[0];
            if (distancia) tmp.distanciaKM = metroHumanize(distancia);
            if (precisao) tmp.precisaoKM = metroHumanize(precisao);
            if (frete) {
                tmp.valorFrete = formatarMoeda(frete);
            }
            if (frete) {
                const distanciaMinima = configuracao.find(
                    ({ chave }) => chave === 'Frete::DistanciaMinima::Km',
                );
                const valorAdicionalCorrida = configuracao.find(
                    ({ chave }) => chave === 'Valor::Adicional::Corrida',
                )?.valor;
                const modalMotorista = modalTransporte.find(
                    ({ id }) => id === 1,
                );
                const viewValorTotalFrete =
                    unidadeSelecionada &&
                    unidadeSelecionada?.unidadeParametro &&
                    unidadeSelecionada?.unidadeParametro.find(
                        ({ chave }) => chave === 'VIEW::VALOR::TOTAL::FRETE',
                    )?.valor === 'YES';
                const valorComRetornoESem = calculaValorFreteComTaxa({
                    frete: frete,
                    porcentagemNegociacao:
                        unidadeSelecionada.porcentagemNegociacao,
                    distancia: distancia + precisao,
                    distanciaMinima: distanciaMinima
                        ? Number(distanciaMinima.valor)
                        : 0,
                    adcionalRetorno: modalMotorista.valorKmRetorno,
                    txMinRetorno: modalMotorista.txMinRetorno,
                    mostrarValorTotal: viewValorTotalFrete,
                    valorAdicionalCorrida: valorAdicionalCorrida
                        ? Number(valorAdicionalCorrida)
                        : 0,
                });
                tmp.valorFreteSemRetorno = formatarMoeda(
                    valorComRetornoESem.valorFreteSemRetorno,
                );
                tmp.valorFreteComRetorno = formatarMoeda(
                    valorComRetornoESem.valorFreteComRetorno,
                );
            }

            if (precisao >= 0) tmp.precisao = precisao;
        }
        return tmp;
    }, [enderecoSelecionado]);
    console.log('distanciaEFrete', distanciaEFrete);
    return (
        <>
            <ContainerBody>
                <ContainerForm>
                    <Form
                        ref={oProps.rForm}
                        onSubmit={handleSubmit}>
                        <p>Qual é o endereço para entrega?</p>
                        <ComponentSelecionarEndereco {...props} />
                    </Form>
                    <GoogleMapWrap>
                        {latLng && latLng.lat !== 0 && latLng.lat !== undefined && (
                        <Map zoomControl={false} style={stylMap} center={latLng} zoom={16}>
                            <TileLayer
                            attribution="&amp;copy <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
                            url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
                            />
                            {latLng && latLng.lat !== 0 && latLng.lat !== undefined && (
                            <Marker 
                                icon={
                                    new L.Icon({
                                        iconUrl: require('leaflet/dist/images/marker-icon.png'),
                                        iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
                                        shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
                                        iconSize: [25, 41],
                                        iconAnchor: [12, 41],
                                        popupAnchor: [1, -34],
                                        tooltipAnchor: [16, -28],
                                        shadowSize: [41, 41]
                                    })
                                } position={{
                                    lat: latLng.lat,
                                    lng: latLng.lng,
                                }} />
                            )}
                            {distanciaEFrete.valorFrete && (
                                <AtomFreteMapa {...distanciaEFrete} />
                            )}
                        </Map>
                        )}
                    </GoogleMapWrap>
                </ContainerForm>
            </ContainerBody>
            <div className="space-button">
                <ButtonLoading
                    value={oProps.labelSubmit || 'Continuar'}
                    onClick={() => oProps.rForm.current.submitForm()}
                    loading={false}
                />
            </div>
        </>
    );
};
export default SelecionarEndereco;
