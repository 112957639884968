import { useMutation, useQuery } from '@apollo/client';
import { FormHandles, SubmitHandler } from '@unform/core';
import { GRAPHQL_PROCURAR_GEOLOCALIZACAO_ENDERECO } from 'gql/procurarGeolocalizacaoEndereco';
import { metroHumanize } from 'libs/GeolocationUtils';
import { calculaValorFreteComTaxa, formatarMoeda } from 'libs/NumberUtils';
import { matchCep } from 'libs/yup/matches';
import { YupUtilsCatch, YupUtilsTry } from 'libs/yup/ShapeValidation';
import React, {
    FC,
    RefObject,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { AuthContext } from 'routes/auth.context';
import { ICidade } from 'types/modules/cidade';
import { IClienteEndereco } from 'types/modules/clienteEndereco';
import * as Yup from 'yup';
import Component from './Component';

interface IGeolocation {
    lat: number;
    lng: number;
}

export interface IMapaQuery {
    geolocalizacao?: IGeolocation;
    limites?: IGeolocation[];
    distancia?: Number;
    duracao?: Number;
    precisao?: Number;
    frete?: Number;
    taxaRetorno?: Number;
}

export interface IResponseQuery {
    bairro?: string;
    cep?: string;
    logradouro?: string;
    municipio?: string;
    numero?: string;
    uf?: string;
    mapa?: IMapaQuery;
    enderecoPontoReferencia?: string;
    enderecoQuadra?: string;
    enderecoLote?: string;
    enderecoNumero?: string;
    enderecoComplemento?: string;
    geoLatitude?: string | number;
    geoLongitude?: string | number;
    id?: string;
}

interface IInitialData {
    enderecoCep?: string;
    enderecoBairro?: string;
    enderecoLogradouro?: string;
    enderecoNumero?: string;
    enderecoPontoReferencia?: string;
    enderecoQuadra?: string;
    enderecoLote?: string;
    enderecoComplemento?: string;
    geoLatitude?: string | number;
    geoLongitude?: string | number;
    cidade?: string;
    uf?: string;
}

interface IFormularioEnderecoTemplate {
    submit(data: any): void;
    fieldsEdit?: IClienteEndereco & {
        clienteId: string;
        cidade: ICidade & {
            uf: {
                uf: string;
            };
        };
    };
    setFieldsEdit?: (
        value: React.SetStateAction<
            IClienteEndereco & {
                clienteId: string;
                cidade: ICidade & {
                    uf: {
                        uf: string;
                    };
                };
            }
        >,
    ) => void;
    submitLoading: boolean;
    destino?: IInitialData;
    setupLocation?: IGeolocation;
    origem?: any;
    formRef: RefObject<FormHandles>;
    tituloFormulario: string;
    removeLegend?: boolean;
}

const FormularioEnderecoTemplate: FC<IFormularioEnderecoTemplate> = ({
    submit,
    submitLoading,
    setupLocation,
    destino,
    origem,
    formRef,
    tituloFormulario,
    fieldsEdit,
    setFieldsEdit,
    removeLegend,
}) => {
    const [viewFields, setViewFields] = useState(typeof removeLegend === 'undefined' ? (
        fieldsEdit ? true : false
    ) : removeLegend);
    const { configuracao, alertConfirm, unidadeSelecionada, modalTransporte } =
        useContext(AuthContext);
    const [variables, setVariables] = useState({});
    const inputSelected = useRef<'cep' | 'numero'>('cep');
    const blockFindByCep = useRef(false);
    const {
        loading: loadingLoadAddress,
        data: atualizarEndereco,
        ...rest
    } = useQuery<{
        procurarGeolocalizacaoEndereco?: IResponseQuery;
    }>(GRAPHQL_PROCURAR_GEOLOCALIZACAO_ENDERECO, {
        skip: Object.keys(variables).length === 0,
        variables,
        onError: ({ message }) => {
            alertConfirm({
                tipo: 'erro',
                titulo: 'Erro',
                conteudo: message || 'Cep informado não existe.',
            });
        },
    });

    const [marker, setMarker] = useState(setupLocation);
    const [bounds, setBounds] = useState(null);

    useEffect(() => {
        if (fieldsEdit) {
            setParamsForm({
                cep: fieldsEdit.enderecoCep,
                bairro: fieldsEdit.enderecoBairro,
                numero: fieldsEdit.enderecoNumero,
                logradouro: fieldsEdit.enderecoLogradouro,
                municipio: fieldsEdit.cidade.nome,
                uf: fieldsEdit.cidade.uf.uf,
                enderecoPontoReferencia: fieldsEdit.enderecoPontoReferencia,
                enderecoComplemento: fieldsEdit.enderecoComplemento,
                enderecoQuadra: fieldsEdit.enderecoQuadra,
                enderecoLote: fieldsEdit.enderecoLote,
                geoLatitude: fieldsEdit.latitude,
                geoLongitude: fieldsEdit.longitude,
                id: `${fieldsEdit.id}`,
            });
            setMarker({
                lat: Number(fieldsEdit.latitude),
                lng: Number(fieldsEdit.longitude),
            });
            setBounds([]);
            setVariables({
                destino: {
                    latitude: Number(fieldsEdit.latitude),
                    longitude: Number(fieldsEdit.longitude),
                    cep: fieldsEdit.enderecoCep,
                    bairro: fieldsEdit.enderecoBairro,
                    logradouro: fieldsEdit.enderecoLogradouro,
                    numero: fieldsEdit.enderecoNumero,
                    enderecoQuadra: fieldsEdit.enderecoQuadra,
                    enderecoLote: fieldsEdit.enderecoLote,
                    municipio: fieldsEdit.cidade.nome,
                    uf: fieldsEdit.cidade.uf.uf,
                },
                origem,
            });
        }
    }, [fieldsEdit]);

    const setParamsForm = useCallback((tmp: IResponseQuery = {}) => {
        formRef.current.setFieldValue('precisao', tmp?.mapa?.precisao || '');
        formRef.current.setFieldValue('distancia', tmp?.mapa?.distancia || '');
        formRef.current.setFieldValue('duracao', tmp?.mapa?.duracao || '');
        formRef.current.setFieldValue('frete', tmp?.mapa?.frete || '');

        formRef.current.setFieldValue('enderecoCep', tmp?.cep || '');
        formRef.current.setFieldValue('enderecoBairro', tmp?.bairro || '');
        formRef.current.setFieldValue('enderecoNumero', tmp?.numero || '');
        formRef.current.setFieldValue('enderecoQuadra', tmp?.enderecoQuadra || '');
        formRef.current.setFieldValue('enderecoLote', tmp?.enderecoLote || '');
        formRef.current.setFieldValue('geoLatitude', tmp?.geoLatitude || '');
        formRef.current.setFieldValue('geoLongitude', tmp?.geoLongitude || '');
        formRef.current.setFieldValue('id', tmp?.id || '');
        formRef.current.setFieldValue(
            'enderecoLogradouro',
            tmp?.logradouro || '',
        );

        formRef.current.setFieldValue('cidade', tmp?.municipio || '');
        formRef.current.setFieldValue('uf', tmp?.uf || '');
    }, []);
    const handleResponseSearchAdress = useCallback(
        (data: IResponseQuery) => {
            if (data.uf.toLowerCase() !== 'go') {
                alertConfirm({
                    tipo: 'erro',
                    titulo: 'Erro',
                    conteudo: `Este endereço está fora dos limites de atendimento - ${data.municipio} - ${data.uf}`,
                });
            }

            blockFindByCep.current = true;
            if (fieldsEdit && fieldsEdit.id) data.id = `${fieldsEdit.id}`;
            setParamsForm(data);
            setMarker(data?.mapa?.geolocalizacao);
            setBounds([]);
            setVariables({
                destino: {
                    latitude: Number(data.mapa.geolocalizacao.lat),
                    longitude: Number(data.mapa.geolocalizacao.lng),
                    cep:
                        data.cep && data.cep.length >= 8
                            ? data.cep
                            : '00.000-000',
                    bairro: data.bairro,
                    logradouro: data.logradouro,
                    numero: data.numero,
                    municipio: data.municipio,
                    uf: data.uf,
                },
                origem,
            });
        },
        [alertConfirm],
    );

    useEffect(() => {
        if (destino?.enderecoCep && destino?.enderecoCep.length > 6) {
            setVariables({
                destino: {
                    ...(destino?.geoLatitude
                        ? {
                              latitude: Number(destino?.geoLatitude),
                              longitude: Number(destino?.geoLongitude),
                          }
                        : {}),
                    cep: destino.enderecoCep,
                    bairro: destino.enderecoBairro,
                    logradouro: destino.enderecoLogradouro,
                    numero: destino.enderecoNumero,
                    enderecoQuadra: destino.enderecoQuadra,
                    enderecoLote: destino.enderecoLote,
                    municipio: destino.cidade,
                    uf: destino.uf,
                },
                origem,
            });
        }
        formRef.current.setFieldValue(
            'enderecoComplemento',
            fieldsEdit?.enderecoComplemento || '',
        );
        formRef.current.setFieldValue(
            'enderecoPontoReferencia',
            fieldsEdit?.enderecoPontoReferencia || '',
        );
    }, []);

    useEffect(() => {
        if (atualizarEndereco) {
            setMarker(
                atualizarEndereco?.procurarGeolocalizacaoEndereco?.mapa
                    ?.geolocalizacao,
            );
            setBounds(
                atualizarEndereco?.procurarGeolocalizacaoEndereco?.mapa
                    ?.limites,
            );
            setParamsForm({
                ...atualizarEndereco?.procurarGeolocalizacaoEndereco,
                enderecoComplemento: fieldsEdit?.enderecoComplemento,
                enderecoPontoReferencia: fieldsEdit?.enderecoPontoReferencia,
                id: `${fieldsEdit ? fieldsEdit?.id : ''}`,
            });
        }
    }, [atualizarEndereco]);

    useEffect(() => {
        if (marker && marker.lat) {
            formRef.current.setFieldValue(
                'geoLongitude',
                marker.lng.toString(),
            );
            formRef.current.setFieldValue('geoLatitude', marker.lat.toString());
        }
    }, [marker]);

    const handleLoadAddress = useCallback(({ target, input = 'cep' }) => {
        inputSelected.current = input;
        if (
            !blockFindByCep.current &&
            formRef.current &&
            formRef.current.getData
        ) {
            const formData: IInitialData = formRef.current.getData();
            if (input === 'cep') {
                formRef.current.setFieldValue('enderecoNumero', '');
            }
            if (formData.enderecoCep.length > 2) {
                const variables =
                    target.getAttribute('name') === 'enderecoCep'
                        ? {
                              origem,
                              destino: {
                                  cep: formData.enderecoCep,
                                  numero: formData.enderecoNumero,
                              },
                          }
                        : {
                              origem,
                              destino: {
                                  cep: formData.enderecoCep,
                                  numero: formData.enderecoNumero,
                                  quadra: formData.enderecoQuadra,
                                  lote: formData.enderecoLote,
                                  logradouro: formData.enderecoLogradouro,
                                  bairro: formData.enderecoBairro,
                                  municipio: formData.cidade,
                                  uf: formData.uf,
                              },
                          };

                setVariables(variables);
            }
        }
    }, []);

    const handleLoadAddressByNumber = useCallback(
        ({ target }) => {
            if (!blockFindByCep.current) {
                handleLoadAddress({ target, input: 'number' });
            }
        },
        [handleLoadAddress],
    );

    const handleSubmit: SubmitHandler<any> = async (data, { reset }) => {
        const { setErrors } = formRef.current as FormHandles;
        try {
            await YupUtilsTry(data, setErrors, {
                enderecoCep: Yup.string()
                    .matches(matchCep, 'CEP inválido')
                    .required('Cep não informado'),
                enderecoBairro: Yup.string().required('Bairro não informado'),
                enderecoLogradouro: Yup.string().required(
                    'Endereço não informado',
                ),
                enderecoQuadra: Yup.string().when(
                    'enderecoNumero',
                    (enderecoNumero, schema) =>
                        !enderecoNumero ? schema.required('Quadra não informada') : schema,
                ),
                enderecoLote: Yup.string().when(
                    'enderecoNumero',
                    (enderecoNumero, schema) =>
                        !enderecoNumero ? schema.required('Lote não informado') : schema,
                ),
            });
            if (fieldsEdit && fieldsEdit.clienteId)
                data.clienteId = fieldsEdit.clienteId;
            submit(data);
            setFieldsEdit(null);
        } catch (err) {
            YupUtilsCatch(err, setErrors);
        }
    };

    const onDragEndMarker = useCallback(
        location => {
            setVariables({
                destino: {
                    latitude: location.lat,
                    longitude: location.lng,
                },
                origem,
            });

            setMarker(location);
            setBounds(null);
        },
        [bounds, origem],
    );

    const myLocationProps = useMemo(
        () => ({
            onDragEndMarker,
            keyMaps: configuracao.find(({ chave }) => chave === 'key_maps')
                .valor,
            bounds,
            marker,
            loading: loadingLoadAddress,
        }),
        [loadingLoadAddress, marker, bounds, configuracao, onDragEndMarker],
    );

    const distanciaEFrete = useMemo(() => {
        let tmp = {
            distanciaKM: null,
            precisaoKM: null,
            precisao: null,
            valorFrete: null,
            duracao: null,
            valorFreteComRetorno: null,
            valorFreteSemRetorno: null,
        };

        if (atualizarEndereco?.procurarGeolocalizacaoEndereco?.mapa) {
            const { frete, distancia, duracao, precisao, taxaRetorno } =
                atualizarEndereco?.procurarGeolocalizacaoEndereco?.mapa;
            if (distancia) tmp.distanciaKM = metroHumanize(distancia);
            if (precisao) tmp.precisaoKM = metroHumanize(precisao);
            if (frete) {
                tmp.valorFrete = formatarMoeda(frete);
            }
            if (frete) {
                const distanciaMinima = configuracao.find(
                    ({ chave }) => chave === 'Frete::DistanciaMinima::Km',
                );
                const valorAdicionalCorrida = configuracao.find(
                    ({ chave }) => chave === 'Valor::Adicional::Corrida',
                )?.valor;
                const modalMotorista = modalTransporte.find(
                    ({ id }) => id === 1,
                );
                const viewValorTotalFrete =
                    unidadeSelecionada &&
                    unidadeSelecionada?.unidadeParametro &&
                    unidadeSelecionada?.unidadeParametro.find(
                        ({ chave }) => chave === 'VIEW::VALOR::TOTAL::FRETE',
                    )?.valor === 'YES';
                const valorComRetornoESem = calculaValorFreteComTaxa({
                    frete: frete as number,
                    porcentagemNegociacao:
                        unidadeSelecionada.porcentagemNegociacao,
                    distancia: Number(distancia) + Number(precisao),
                    distanciaMinima: distanciaMinima
                        ? Number(distanciaMinima.valor)
                        : 0,
                    adcionalRetorno: modalMotorista.valorKmRetorno,
                    txMinRetorno: modalMotorista.txMinRetorno,
                    mostrarValorTotal: viewValorTotalFrete,
                    valorAdicionalCorrida: valorAdicionalCorrida
                        ? Number(valorAdicionalCorrida)
                        : 0,
                });
                tmp.valorFreteSemRetorno = formatarMoeda(
                    valorComRetornoESem.valorFreteSemRetorno,
                );
                tmp.valorFreteComRetorno = formatarMoeda(
                    valorComRetornoESem.valorFreteComRetorno,
                );
            }
            if (precisao) tmp.precisao = precisao;
            if (duracao) tmp.duracao = duracao;
        }
        return tmp;
    }, [marker, atualizarEndereco]);

    const props = {
        tituloFormulario,
        formRef,
        destino,
        submitLoading,
        handleResponseSearchAdress,
        loadingLoadAddress,
        handleLoadAddress,
        handleLoadAddressByNumber,
        inputName: inputSelected.current,
        handleSubmit,
        myLocationProps,
        distanciaEFrete,
        removeLegend,
        viewFields,
        setViewFields,
        blockFindByCep: blockFindByCep.current,
    };

    return <Component {...props} />;
};
export default FormularioEnderecoTemplate;
